import { Typography, Stack, Divider } from "@mui/material";
import translations from "translations";
import { useCurrentProject, useLanguage } from "hooks";
import axios from "axios";
import { getLambdaAxiosWithAuth } from "utils/request";
import { IKeyPayload } from "components/KMLUploader/MetaSelector";
import KMLUploader from "components/KMLUploader";
import { useState } from "react";
import { getPowerlineUploadSignedURL } from "components/KMLUploader/utils";

const initialKeys: IKeyPayload = {
  poleClientID: null,
  poleNote: null,
  powerlineClientID: null,
  powerlineFeedBay: null,
  powerlineTopologyColor: null,
  powerlineFeedStation: null,
};

export default function PowerlineUpload() {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const [keys, setKeys] = useState<IKeyPayload>(initialKeys);

  async function onSave(kmlData: string, kmlKeys: IKeyPayload) {
    const blob = new Blob([kmlData], { type: "kml" });
    const date = new Date();
    const filename = `${date.toISOString()}.kml`;
    const signedURL: string = await getPowerlineUploadSignedURL(
      filename,
      currentProject.id
    );

    await axios.put(signedURL, blob, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const lambdaAxios = await getLambdaAxiosWithAuth();
    await lambdaAxios.post<{ powerlines; poles }>(
      "/powerline/upload",
      {
        fileLinks: [signedURL],
        ...kmlKeys,
      },
      {
        headers: {
          MissionID: currentProject.id,
        },
      }
    );
  }

  return (
    <Stack spacing={2}>
      <KMLUploader
        projectID={currentProject?.id ?? null}
        onSave={onSave}
        keys={keys}
        setKeys={setKeys}
      />
      <Divider sx={{ pt: 4 }} />
      <Typography sx={{ color: "text.secondary" }}>
        {
          translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
            .Footnote[language]
        }
      </Typography>
    </Stack>
  );
}
