import {
  CloseOutlined,
  ModeEditOutlineOutlined,
  NavigateBefore,
  NavigateNext,
  ThumbDownAlt,
  ThumbUpAlt,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Tooltip, FormControl, MenuItem, Select } from "@mui/material";
import {
  approveFalsePositiveObjects,
  goToNextFalsePositiveImage,
  goToPreviousFalsePositiveImage,
  getFalsePositiveReviewObjects,
  getMlModels,
  setSelectedModel,
} from "state/actions";
import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "hooks";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";

export default function FalsePositiveReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Collect state from redux
  const reviewObjects = useSelector(
    (state) => state.objects.falsePositiveReview
  );
  const mlModels = useSelector((state) => state.objects.mlModels);
  const selectedModel = useSelector((state) => state.objects.selectedModel);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const image: number = parseInt(params.image);
  const isMountedRef = useRef(true);

  const [mlModelDetections, setMlModelDetections] = React.useState("");

  const handleChange = (event) => {
    dispatch(setSelectedModel(event.target.value as string));
  };

  useEffect(() => {
    if (selectedModel) {
      const mod = mlModels.filter((e) => e.id === parseInt(selectedModel));
      const detec = mod[0].types;
      setMlModelDetections(detec.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel]);

  const goToImage = useCallback(
    (image: number) => {
      if (isMountedRef.current) {
        navigate(
          `../../${image}/falsepositive-review${window.location.search}`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    isMountedRef.current = true;
    if (selectedModel !== null) {
      dispatch(
        getFalsePositiveReviewObjects(
          image,
          selectedModel,
          mlModelDetections,
          goToImage
        )
      );
    }
    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    goToImage,
    image,
    location.pathname,
    window.location.search,
    mlModelDetections,
  ]);

  useEffect(() => {
    dispatch(getMlModels());
  }, [getMlModels]);

  useEffect(() => {
    if (mlModels.length > 0 && !selectedModel) {
      dispatch(setSelectedModel(String(mlModels[0].id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mlModels]);

  let newUrl = location.pathname;
  const regex = /\/falsepositive-review/gi;
  newUrl = newUrl.replaceAll(regex, "");

  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <div className="reviewToolWrapper" style={{ marginLeft: "150px" }}>
      <>
        {true && (
          <>
            <div
              style={{
                transform: "translate(-50%, -28px)",
                marginRight: 50,
                padding: 10,
                borderRadius: 8,
                width: 250,
                backgroundColor: "#ffffffad",
              }}
            >
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  value={selectedModel}
                  onChange={handleChange}
                >
                  {mlModels.map((e, i) => (
                    <MenuItem key={`${i}`} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="backgroundControll">
              <Tooltip title="Go to previous image" placement="left" arrow>
                <NavigateBefore
                  className="navigationArrow"
                  fontSize="large"
                  sx={{ color: "#006FEB" }}
                  onClick={() =>
                    dispatch(
                      goToPreviousFalsePositiveImage(
                        image,
                        selectedModel,
                        mlModelDetections,
                        goToImage
                      )
                    )
                  }
                />
              </Tooltip>

              <div className="menuWrapper">
                <div className="circleWrapper">
                  <div className="circle green">
                    <Tooltip title="Approve objects" placement="top" arrow>
                      <ThumbUpAlt
                        sx={{ color: "#ffff" }}
                        className="thumb"
                        onClick={() =>
                          dispatch(
                            approveFalsePositiveObjects(
                              image,
                              parseInt(selectedModel),
                              true,
                              true,
                              false,
                              () =>
                                dispatch(
                                  getFalsePositiveReviewObjects(
                                    image,
                                    selectedModel,
                                    mlModelDetections,
                                    goToImage
                                  )
                                )
                            )
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                  <div className="circle red">
                    <Tooltip
                      title="Flag image for reannotation"
                      placement="top"
                      arrow
                    >
                      <ThumbDownAlt
                        sx={{ color: "#ffff" }}
                        className="thumb"
                        onClick={() =>
                          dispatch(
                            approveFalsePositiveObjects(
                              image,
                              parseInt(selectedModel),
                              true,
                              true,
                              true,
                              () =>
                                dispatch(
                                  getFalsePositiveReviewObjects(
                                    image,
                                    selectedModel,
                                    mlModelDetections,
                                    goToImage
                                  )
                                )
                            )
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="subMenuWrapper">
                  <Tooltip
                    title="Toggle visibility for bounding boxes. Does not change anything on the image"
                    placement="bottom"
                    arrow
                  >
                    <VisibilityOutlined
                      sx={{ color: "#ffff" }}
                      onClick={toggleBoundingBox}
                    />
                  </Tooltip>
                  <Tooltip title="Open image editor" placement="bottom" arrow>
                    <Link
                      to={`annotate${location.search}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <ModeEditOutlineOutlined sx={{ color: "#ffff" }} />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Exit review mode" placement="bottom" arrow>
                    <CloseOutlined
                      sx={{ color: "#ffff" }}
                      onClick={() => {
                        navigate(`${newUrl}${location.search}`);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>

              <Tooltip title="Go to next image" placement="right" arrow>
                <NavigateNext
                  className="navigationArrow green"
                  fontSize="large"
                  sx={{ color: "#006FEB" }}
                  onClick={() =>
                    dispatch(
                      goToNextFalsePositiveImage(
                        image,
                        selectedModel,
                        mlModelDetections,
                        goToImage
                      )
                    )
                  }
                />
              </Tooltip>
            </div>
          </>
        )}
      </>

      <div className="reviewImagesCount">
        {reviewObjects.current_image_position}/
        {reviewObjects.total_images_to_review}
      </div>
    </div>
  );
}
