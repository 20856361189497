import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CanvasProvider } from "views/AnnotationTool/provider";
import Slider from "views/image/ThermalTool";
import PreviewSlider from "../PreviewSlider";
import GeneralImagePreviewSlider from "../GeneralImagePreviewSlider/GeneralImagePreviewSlider";
import Canvas from "./Canvas";
import { Chip, Box } from "@mui/material";
import { getProjectName } from "utils/utils";
import { useSelector } from "hooks";

export default function AnnotationPreview({ reviewMode, widthScale }) {
  // Collect data from the URL
  const params = useParams();
  const location = useLocation();
  const mission = parseInt(params.mission);
  const image = params.image;
  const previousDefectImages = useSelector(
    (state) => state.imageViewer.previousDefectImages
  );

  // canLoadCluster will prevent the thumbnails from
  // loading until the main image is loaded.
  const [canLoadClusterImages, setCanLoadClusterImages] = useState(false);

  // Reset the loading status when the image
  // changes.
  useEffect(() => {
    setCanLoadClusterImages(false);
  }, [image]);

  // Function is called when the image is loaded
  // and will allow thumbnails to load.
  function onImageLoad() {
    setCanLoadClusterImages(true);
  }

  const currentProject = getProjectName(mission);
  let previousProject = null;
  if (previousDefectImages.length > 0) {
    previousProject = getProjectName(previousDefectImages[0]?.project);
  }

  return (
    <div
      className="noselect"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {location.pathname.includes("thermal") ? (
        <Slider />
      ) : (
        <>
          {previousDefectImages.length > 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Chip
                  sx={{ alignSelf: "center", margin: "5px" }}
                  label={currentProject?.year}
                  color="info"
                />
                <PreviewSlider canLoad={canLoadClusterImages} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Chip
                  sx={{ alignSelf: "center", margin: "5px" }}
                  label={previousProject?.year}
                  color="info"
                />
                <GeneralImagePreviewSlider canLoad={canLoadClusterImages} />
              </Box>
            </>
          ) : (
            <PreviewSlider canLoad={canLoadClusterImages} />
          )}
        </>
      )}
      <CanvasProvider>
        <Canvas
          onLoad={onImageLoad}
          reviewMode={reviewMode}
          widthScale={widthScale}
        />
      </CanvasProvider>
    </div>
  );
}
