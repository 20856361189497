import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { List } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useLanguage } from "hooks";

const KEY = "lists";
const ENABLED_VALUE = "1";

export default function Lists() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguage();
  const toggleLists = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <RoleWrapper keyName="addImageToList">
      <MenuItem
        tooltip={translations.ImageViewer.List[language]}
        onClick={toggleLists}
        id="image-assign-to-list"
      >
        <List />
      </MenuItem>
    </RoleWrapper>
  );
}
