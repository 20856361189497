import { Box, Chip, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { IPoleComponent } from "views/PoleViewer/api";
import { SummaryContext } from "views/PoleViewer/provider";

type Props = {
  onClick: (e) => void;
  component: IPoleComponent;
  selected: boolean;
};

export default function PoleComponentItem({
  onClick,
  component,
  selected,
}: Props) {
  const summaryContext = useContext(SummaryContext);

  const itemSummary = summaryContext.components
    .filter((c) => c.id === component.id)
    .sort((a, b) => a.id - b.id)
    .map((c) => {
      return {
        id: c.id,
        name: c.name,
        status: c.placements.sort((a, b) => a.id - b.id),
      };
    });
  let color = "transparent";
  if (itemSummary.length > 0) {
    const item = itemSummary[0];

    if (item.status.length > 0) {
      const status = item.status.sort((a, b) => a.id - b.id)[0];
      if (!!status) {
        color = status.value.color;
      }
    }
  }

  return (
    <Chip
      onClick={onClick}
      sx={{
        backgroundColor: selected ? "#1876D1" : "#DEDEDE",
        color: selected ? "background.paper" : "text.primary",
        "&:hover": {
          backgroundColor: selected ? "#1E8FFF" : "#EDEDED",
          color: selected ? "background.paper" : "text.primary",
        },
      }}
      label={
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{component.name}</Typography>
          <Box
            sx={{
              width: 14,
              height: 14,
              background: color,
              borderRadius: "50%",
            }}
          />
        </Stack>
      }
    />
  );
}
