import { Add, Clear } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SummaryContext } from "views/PoleViewer/provider";

type Props = {
  open: boolean;
  onClose: () => void;
  componentID: number;
};

export default function AddPoleComponentPopup({
  open,
  onClose,
  componentID,
}: Props) {
  const [searchParams] = useSearchParams();
  const poleID = parseInt(searchParams.get("pole"));
  const summaryContext = useContext(SummaryContext);

  const [selectedNewComponent, setSelectedNewComponent] = useState<
    number | null
  >(null);

  const usedComponentIDs = summaryContext.components.map((c) => c.id);
  const remainingComponents = summaryContext.availableComponents.filter(
    (c) => !usedComponentIDs.includes(c.id)
  );

  const selectedComponent = summaryContext.components.find(
    (c) => c.id === componentID
  );
  const availableComponent = summaryContext.availableComponents.find(
    (c) => c.id === componentID
  );

  const usedPlacementsIDs = !!selectedComponent
    ? selectedComponent.placements.map((p) => p.id)
    : [];
  const remainingPlacements = !!availableComponent
    ? availableComponent.placements.filter(
        (p) => !usedPlacementsIDs.includes(p.id)
      )
    : [];

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack p={2} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Add placement</Typography>
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        </Stack>
        {remainingPlacements.length > 0 && (
          <Typography>
            Select a location for <b>{selectedComponent?.name}</b>
          </Typography>
        )}
        {remainingPlacements.map((placement) => (
          <Chip
            label={placement?.name}
            onClick={() =>
              setSelectedNewComponent(
                selectedNewComponent === placement.id ? null : placement.id
              )
            }
            sx={{
              backgroundColor:
                placement.id === selectedNewComponent ? "#1876D1" : "#DEDEDE",
              color:
                placement.id === selectedNewComponent
                  ? "background.paper"
                  : "text.primary",
              "&:hover": {
                backgroundColor:
                  placement.id === selectedNewComponent ? "#1E8FFF" : "#EDEDED",
                color:
                  placement.id === selectedNewComponent
                    ? "background.paper"
                    : "text.primary",
              },
            }}
          />
        ))}
        {remainingPlacements.length > 0 && <Divider />}
        <Accordion
          expanded={remainingPlacements.length === 0 ? true : undefined}
        >
          <AccordionSummary>
            <Typography>
              {remainingPlacements.length > 0
                ? "or, create a new placement"
                : "Create a new placement"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack mt={1}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  const formData = new FormData(e.currentTarget);
                  const name = formData.get("name") as string;
                  let imageRegexPattern = formData.get(
                    "image_regex_pattern"
                  ) as string;
                  if (!imageRegexPattern) {
                    imageRegexPattern = null;
                  }
                  if (name.length > 0) {
                    summaryContext
                      .createComponentPlacement(
                        componentID,
                        name,
                        imageRegexPattern
                      )
                      .then(({ newID }) => {
                        const component =
                          summaryContext.availableComponents.find(
                            (c) => c.id === componentID
                          );
                        const system = summaryContext.poleValueSystems.find(
                          (s) => s.id === component.value_system_id
                        );
                        const bestValue = system.values.sort(
                          (a, b) => a.id - b.id
                        )[0];
                        summaryContext.addPoleStatus(
                          poleID,
                          componentID,
                          newID,
                          bestValue.id
                        );

                        onClose();
                      });
                  }
                }}
              >
                <TextField
                  sx={{ my: 2 }}
                  label="Name"
                  name="name"
                  autoFocus
                  fullWidth
                  variant="standard"
                />
                <TextField
                  sx={{ my: 2 }}
                  label="Image regex pattern"
                  name="image_regex_pattern"
                  fullWidth
                  variant="standard"
                />
                <Button
                  color="success"
                  fullWidth
                  variant="contained"
                  startIcon={<Add />}
                  type="submit"
                >
                  Add new placement
                </Button>
              </form>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Button
          onClick={() => {
            if (selectedNewComponent) {
              summaryContext.addPoleStatus(
                poleID,
                componentID,
                selectedNewComponent,
                1
              );

              onClose();
            }
          }}
          variant="contained"
          disabled={!selectedNewComponent}
        >
          Add selected placement to pole
        </Button>
      </Stack>
    </Dialog>
  );
}
