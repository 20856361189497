import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormGroup,
} from "@mui/material";
import { useStateMachineExecutions, useStateMachines } from "hooks";
import { useParams, useNavigate } from "react-router-dom";

export default function StateMachines() {
  const navigate = useNavigate();
  const params = useParams();
  const stateMachineId = parseInt(params.state_machine_id);
  const { stateMachines } = useStateMachines();
  const { startExecution, loadExecutions } =
    useStateMachineExecutions(stateMachineId);
  const currentStateMachine = stateMachines.find(
    (stateMachine) => stateMachine.id === stateMachineId
  );

  useEffect(() => {
    loadExecutions(stateMachineId);
  }, [stateMachineId]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const inputData = formData.get("input") as string;
    const input = JSON.parse(inputData);

    startExecution(stateMachineId, name, input).then(() => {
      navigate("..");
    });
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate("..", { replace: true });
      }}
    >
      <DialogTitle>
        Trigger batchjob for {currentStateMachine?.name}
      </DialogTitle>

      <form onSubmit={onSubmit}>
        <FormGroup sx={{ px: 2, minWidth: 400, position: "relative" }}>
          <label htmlFor="name">Name</label>
          <input
            style={{
              width: "100%",
            }}
            type="text"
            id="name"
            name="name"
          />

          <label htmlFor="input">Input</label>
          <textarea
            id="inputSchema"
            name="input"
            style={{
              minHeight: 100,
              width: "100%",
            }}
            defaultValue={JSON.stringify(currentStateMachine?.inputSchema)
              ?.replaceAll("{", "{\n  ")
              ?.replaceAll(",", ",\n  ")
              ?.replaceAll("}", "\n}")
              ?.replaceAll(":", ": ")}
          />
        </FormGroup>
        <DialogActions>
          <Button variant="contained" type="submit">
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate("..", { replace: true })}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
