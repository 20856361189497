import SortIcon from "@mui/icons-material/Sort";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";

interface IProps {
    setSelectedEntry: (string) => void;
}

export default function OrderByDropDown({
    setSelectedEntry
}: IProps) {
    const [sortingDropDownAnchor, setSortingDropDownAnchor] = useState(null);
    const closeSortingDropDown = (e) => {
      setSortingDropDownAnchor(null);
      setSelectedEntry(e.target.id);
    };

    return <>
        <Tooltip title="Change image sort" placement="bottom" arrow>
            <SortIcon
                sx={{ color: "#ffff" }}
                onClick={(e) => {
                    setSortingDropDownAnchor(e.target);
                }}
            />
        </Tooltip>
        <Menu
            anchorEl={sortingDropDownAnchor}
            open={!!sortingDropDownAnchor}
            onClose={() => setSortingDropDownAnchor(null)}
        >
            <MenuItem
                id="confidence_desc"
                style={{ padding: "5px" }}
                onClick={closeSortingDropDown}
            >
                Confidence (Descending)
            </MenuItem>
            <MenuItem
                id="confidence_asc"
                style={{ padding: "5px" }}
                onClick={closeSortingDropDown}
            >
                Confidence (Ascending)
            </MenuItem>
            <MenuItem
                id="id"
                style={{ padding: "5px" }}
                onClick={closeSortingDropDown}
            >
                Image ID
            </MenuItem>
        </Menu>
    </>;
}