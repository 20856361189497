interface IImage {
  id: number;
  src: string;
}

export const handleImageSelect = (
  id: number,
  index: number,
  shiftPressed: boolean,
  lastSelectedImageIndex: number,
  setImageID: React.Dispatch<React.SetStateAction<number[]>>,
  setLastSelectedImageIndex: React.Dispatch<React.SetStateAction<number>>,
  images: IImage[],
  handleImageActive: (id: number) => string
) => {
  if (handleImageActive(id) === "active") return;
  if (shiftPressed && lastSelectedImageIndex !== -1) {
    const start = Math.min(lastSelectedImageIndex, index);
    const end = Math.max(lastSelectedImageIndex, index);

    const selectedRangeIndexes = Array.from(
      { length: end - start + 1 },
      (_, i) => start + i
    );
    const activeSelectedRangeIndexes = selectedRangeIndexes.filter(
      (selectedIndex) =>
        handleImageActive(images[selectedIndex].id) !== "active"
    );
    const selectedRangeIDs = activeSelectedRangeIndexes.map(
      (selectedIndex) => images[selectedIndex].id
    );

    setImageID((prevImageID) => {
      const newImageID = prevImageID.filter(
        (imageIndex) => !selectedRangeIndexes.includes(imageIndex)
      );
      return [...newImageID, ...selectedRangeIDs];
    });
    setLastSelectedImageIndex(-1);
  } else if (lastSelectedImageIndex === -1) {
    setImageID((prevImageID) => {
      if (prevImageID.includes(id)) {
        setLastSelectedImageIndex(-1);
        return prevImageID.filter((imageId) => imageId !== id);
      } else {
        setLastSelectedImageIndex(index);
        return [...prevImageID, id];
      }
    });
  } else {
    setImageID((prevImageID) => {
      if (prevImageID.includes(id)) {
        setLastSelectedImageIndex(-1);
        return prevImageID.filter((imageId) => imageId !== id);
      } else {
        return [...prevImageID, id];
      }
    });
  }
};
