import { Alert, Card, Chip, Divider, Stack, Typography } from "@mui/material";
import { useStateMachineExecutions } from "hooks";
import { useParams } from "react-router-dom";
import Loading from "views/Loading";
import ItemColumn from "views/options/pole/status/ItemColumn";

function fromJsonToString(json: Record<string, unknown>) {
  return JSON.stringify(json)
    ?.replaceAll("{", "{\n  ")
    ?.replaceAll(",", ",\n  ")
    ?.replaceAll("}", "\n}")
    ?.replaceAll(":", ": ");
}

export default function StateMachine() {
  const params = useParams();

  const stateMachineId = parseInt(params.state_machine_id);
  const executionId = parseInt(params.execution_id);

  const { executions, loading } = useStateMachineExecutions(stateMachineId);
  const currentExecution = executions.find(
    (execution) => execution.id === executionId
  );

  return (
    <Stack
      sx={{
        flex: 1,
        padding: 2,
        height: "100%",
        maxWidth: "calc( 33% - 8px )",
        borderRadius: 2,
      }}
    >
      {executionId ? (
        currentExecution ? (
          <Card sx={{ background: "white", borderRadius: 2, p: 2 }}>
            <ItemColumn
              title="State Execution"
              description={currentExecution.name}
            >
              <Chip label={currentExecution.status} />
              <Divider style={{ marginBottom: "1rem", marginTop: "0.5rem" }} />
              <Stack spacing={3}>
                <Stack>
                  <Typography variant="h6">Input</Typography>
                  <div
                    id="input_json"
                    style={{
                      backgroundColor: "#3b3c43",
                      borderRadius: 8,
                      color: "white",
                      fontFamily: "monospace",
                      maxHeight: 200,
                      overflow: "auto",
                      whiteSpace: "pre-line",
                      padding: "1rem",
                    }}
                  >
                    {fromJsonToString(currentExecution.input)}
                  </div>
                </Stack>
                <Stack>
                  <Typography variant="h6">Output</Typography>
                  <div
                    id="input_json"
                    style={{
                      backgroundColor: "#3b3c43",
                      borderRadius: 8,
                      color: "white",
                      fontFamily: "monospace",
                      maxHeight: 200,
                      overflow: "auto",
                      whiteSpace: "pre-line",
                      padding: "1rem",
                    }}
                  >
                    {fromJsonToString(currentExecution.output)}
                  </div>
                </Stack>
              </Stack>
            </ItemColumn>
          </Card>
        ) : loading ? (
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Loading color="black" relative size={30} />
            <Typography>Loading</Typography>
          </Stack>
        ) : (
          <Alert severity="error">State machine not found</Alert>
        )
      ) : (
        <Alert severity="info">No machine selected</Alert>
      )}
    </Stack>
  );
}
