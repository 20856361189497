import { Divider, IconButton, Stack } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BatchManagerProvider from "providers/BatchManagerProvider";

import StateMachines from "./StateMachines";
import { StateMachineExecution, StateMachineExecutions } from ".";
import FullPage from "components/FullPage";
import StateMachine from "./StateMachine";
import { Clear } from "@mui/icons-material";

export default function StateMachineManager() {
  const tabs = [
    {
      label: "Templates",
      link: "/options/pole/status/template",
    },
    {
      label: "Components",
      link: "/options/pole/status/component",
    },
  ];

  let currentTab = 0;

  for (let i = 0; i < tabs.length; i++) {
    if (window.location.pathname.includes(tabs[i].link)) {
      currentTab = i;
      break;
    }
  }
  const navigate = useNavigate();
  const params = useParams();
  const currentExecutionId = parseInt(params.execution_id);

  return (
    <>
      <FullPage title={"State Machine Manager"} tabs={[]} tab={-1}>
        <IconButton
          sx={{ position: "absolute", top: 50, right: 8 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Clear />
        </IconButton>
        <BatchManagerProvider>
          <Stack
            spacing={2}
            flex={1}
            sx={{ background: "#EAEFF1", height: "100%" }}
          >
            <Stack
              id="page_content"
              direction="row"
              spacing={2}
              flex={1}
              height="100%"
              alignContent="flex-start"
              justifyContent="flex-start"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <StateMachines />
              <StateMachineExecutions />
              {currentExecutionId ? (
                <StateMachineExecution />
              ) : (
                <StateMachine />
              )}
            </Stack>
          </Stack>
          <Outlet />
        </BatchManagerProvider>
      </FullPage>
    </>
  );
}
