import colorAlpha from "color-alpha";

const BOUNDING_BOX_LINE_OPACITY = 1;

type Props = {
  x: number;
  h: number;
  w: number;
  y: number;
  iw: number;
  dashed: boolean;
  color: string;
  className: string;
  selected: boolean;
  isDefect: boolean;
  rotation: number;
};

export default function ColorBox({
  x,
  y,
  w,
  h,
  iw,
  color,
  className,
  dashed,
  selected,
  isDefect,
  rotation,
}: Props) {
  return (
    <g
      transform={`translate(${x + w / 2} ${
        y + h / 2
      }) rotate(${rotation}) translate(${-w / 2} ${-h / 2})`}
    >
      {selected && (
        <rect
          paintOrder={1}
          className={className}
          x={-5}
          y={-5}
          width={w + 10}
          height={h + 10}
          stroke={colorAlpha("#fff", BOUNDING_BOX_LINE_OPACITY)}
          fill="transparent"
          strokeDasharray={"10 10"}
          strokeWidth={3}
        />
      )}
      <rect
        paintOrder={1}
        className={className}
        x={0}
        y={0}
        width={w}
        height={h}
        stroke={colorAlpha(color, BOUNDING_BOX_LINE_OPACITY)}
        fill={"transparent"}
        strokeDasharray={dashed ? "10 10" : undefined}
        strokeWidth={1.5}
      />
      <circle
        paintOrder={1}
        cx={0}
        cy={0}
        r={selected ? iw * 0.0003 + 4 : iw * 0.0003 + 1}
        fill={isDefect ? "#FF0000" : "#00ff44"}
      />
      <circle
        paintOrder={1}
        cx={w}
        cy={0}
        r={selected ? iw * 0.0003 + 4 : iw * 0.0003 + 1}
        fill={isDefect ? "#FF0000" : "#00ff44"}
      />
      <circle
        paintOrder={1}
        cx={w}
        cy={h}
        r={selected ? iw * 0.0003 + 4 : iw * 0.0003 + 1}
        fill={isDefect ? "#FF0000" : "#00ff44"}
      />
      <circle
        paintOrder={1}
        cx={0}
        cy={h}
        r={selected ? iw * 0.0003 + 4 : iw * 0.0003 + 1}
        fill={isDefect ? "#FF0000" : "#00ff44"}
      />
    </g>
  );
}
