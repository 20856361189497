import { createContext } from "react";
import { IMatrix } from "../../types";

import { compose, scale } from "transformation-matrix";

const defaultMatrix = compose(scale(0, 0));

export interface IContextValue {
  mode:
    | "addDefect"
    | "addDetection"
    | "addSteelwork"
    | "noneDefectVerification"
    | "view";
  setMode: (
    mode:
      | "addDefect"
      | "addDetection"
      | "addSteelwork"
      | "noneDefectVerification"
      | "view"
  ) => void;
  matrix: IMatrix;
  setMatrix: (matrix: IMatrix) => void;
  imageDimensions: {
    naturalWidth: number | undefined;
    naturalHeight: number | undefined;
  };
  changeImageDimensions: (imageDimensions) => void;
  zoomSpeed: number;
  setZoomSpeed: (zoomSpeed: number) => void;
  dragged: boolean;
  setDragged: (dragged: boolean) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  mode: "view",
  setMode: () => {},
  matrix: defaultMatrix,
  setMatrix: () => {},
  imageDimensions: {
    naturalWidth: undefined,
    naturalHeight: undefined,
  },
  changeImageDimensions: () => {},
  zoomSpeed: 17,
  setZoomSpeed: () => {},
  dragged: false,
  setDragged: () => {}
});

export { context };
