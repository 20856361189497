import { createContext } from "react";
import { IAnnotation, ISummaryItem } from "../../api";

export interface IContextValue {
  data: IAnnotation[];
  summary: ISummaryItem[];
  loading: boolean;
  error?: string;
  path: string[];
  update: () => void;
  addAnnotation: (annotation: IAnnotation) => void;
  updateAnnotation: (
    annotationID: number,
    annotation: IAnnotation,
    temporary: boolean
  ) => void;
  deleteAnnotation: (annotationID: number) => void;
  addItemToBox: (poleID: number, boxID: number, imageID: number) => void;
  deleteBoxItem: (poleID: number, boxID: number, itemID: number) => void;
  poleComment: string;
  updatePoleComment: (
    poleID: number,
    comment: string | null,
    temporary?: boolean
  ) => void;
  getItemPlacementImages: (placementID: number) => void;
  poleItemPlacementImages: number[];
  addItemPlacementImage: (
    poleID: number,
    placementID: number,
    imageID: number
  ) => void;
  deleteItemPlacementImage: (
    poleID: number,
    placementID: number,
    imageID: number
  ) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  data: [],
  summary: [],
  update: () => {},
  loading: false,
  error: undefined,
  path: [],
  addAnnotation: () => {},
  updateAnnotation: () => {},
  deleteAnnotation: () => {},
  addItemToBox: () => {},
  deleteBoxItem: () => {},
  poleComment: "",
  updatePoleComment: () => {},
  getItemPlacementImages: () => {},
  poleItemPlacementImages: [],
  addItemPlacementImage: () => {},
  deleteItemPlacementImage: () => {},
});

export { context };
