import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { CheckCircle } from "@mui/icons-material";
import { ImageMeta } from "interfaces";
import { useParams } from "react-router-dom";
import { useLanguage } from "hooks";
type Props = {
  imageMeta: ImageMeta;
  supervisorApproveImage: (
    mission: number,
    image: number,
    value: boolean
  ) => void;
};

export default function SupervisorApproved({
  imageMeta,
  supervisorApproveImage,
}: Props) {
  const params = useParams();
  const { language } = useLanguage();
  const mission = parseInt(params.mission);

  return !!imageMeta ? (
    <RoleWrapper keyName="supervisorApprove">
      <MenuItem
        tooltip={translations.ImageViewer.Approve[language]}
        onClick={() =>
          supervisorApproveImage(mission, imageMeta.id, !imageMeta.approved)
        }
        id="image-supervisor-approve"
      >
        <CheckCircle
          style={{
            color: imageMeta.approved ? "green" : "red",
          }}
        />
      </MenuItem>
    </RoleWrapper>
  ) : null;
}
