import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormGroup,
} from "@mui/material";
import { useStateMachines } from "hooks";
import { useNavigate } from "react-router-dom";

export default function CreateStateMachine() {
  const navigate = useNavigate();
  const { addStateMachine } = useStateMachines();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const description = formData.get("description") as string;
    const inputSchema = formData.get("input") as string;
    const outputSchema = formData.get("output") as string;
    const arn = formData.get("arn") as string;

    addStateMachine({
      name,
      arn,
      description,
      inputSchema: JSON.parse(inputSchema),
      outputSchema: JSON.parse(outputSchema),
    }).then(() => {
      navigate("..");
    });
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate("..", { replace: true });
      }}
    >
      <DialogTitle>Add state machine</DialogTitle>

      <form onSubmit={onSubmit}>
        <FormGroup sx={{ px: 2, width: 300 }}>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" />

          <label htmlFor="description">Description</label>
          <textarea id="description" name="description" />

          <label htmlFor="input">Input</label>
          <textarea id="inputSchema" name="input" />

          <label htmlFor="output">Output</label>
          <textarea id="outputSchema" name="output" />

          <label htmlFor="arn">ARN</label>
          <input type="text" id="arn" name="arn" />
        </FormGroup>
        <DialogActions>
          <Button variant="contained" type="submit">
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate("..", { replace: true })}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
