import { useContext } from "react";
import { context as BatchManagerContext } from "providers/BatchManagerProvider";

export function useStateMachines() {
  const {
    stateMachines,
    loadStateMachines,
    loading,
    errorMessage,
    setErrorMessage,
    addStateMachine,
  } = useContext(BatchManagerContext);

  return {
    stateMachines,
    loadStateMachines,
    loading,
    errorMessage,
    addStateMachine,
    setErrorMessage,
  };
}
