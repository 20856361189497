import { useContext, useState, MouseEvent } from "react";
import { Chip, Divider, Menu, MenuItem } from "@mui/material";
import { SummaryContext } from "views/PoleViewer/provider";
import { IPoleTemplate } from "views/PoleViewer/api";
import { useSearchParams, Link } from "react-router-dom";
import { addPoleTemplate, addPoleTemplateItem } from "../../api";
import { toast } from "react-toastify";
import { useCurrentProject } from "hooks";

export default function Templates() {
  const [params] = useSearchParams();
  const poleID = parseInt(params.get("pole"));
  const currentProject = useCurrentProject();
  const summaryContext = useContext(SummaryContext);

  function onTemplateClick(template: IPoleTemplate) {
    const confirmation = window.confirm(
      "Are you sure you want to load this template?"
    );
    if (!confirmation) return;

    // Register already existing combinations
    const enteredValues = new Set();
    for (const component of summaryContext.components) {
      for (const placement of component.placements) {
        const key = `${component.id}-${placement.id}`;
        enteredValues.add(key);
      }
    }

    for (const item of template.items) {
      const component = summaryContext.availableComponents.find(
        (c) => c.id === item.component.id
      );
      if (!component) continue;
      const componentValueSystem = summaryContext.poleValueSystems.find(
        (c) => c.id === component?.value_system_id
      );
      if (!componentValueSystem) continue;
      if (componentValueSystem.values.length === 0) continue;
      const bestValue = componentValueSystem.values.sort(
        (a, b) => a.id - b.id
      )[0];

      const key = `${item.component.id}-${item.placement.id}`;
      if (enteredValues.has(key)) continue;
      // Add item from the template
      summaryContext.addPoleStatus(
        poleID,
        item.component.id,
        item.placement.id,
        bestValue.id
      );
    }
  }

  async function openSaveTemplateDialog() {
    const confirmation = window.confirm("Save current pole as a template?");
    if (!confirmation) return;

    const templateName = window.prompt(
      "What name do you want to give the template?"
    );
    if (!templateName) return;

    const templateItems = [];
    for (const component of summaryContext.components) {
      for (const placement of component.placements) {
        templateItems.push({
          componentID: component.id,
          placementID: placement.id,
        });
      }
    }
    const templateID: number = await addPoleTemplate(
      templateName,
      currentProject?.group?.id
    );
    for (const item of templateItems) {
      await addPoleTemplateItem(templateID, item.componentID, item.placementID);
    }
    summaryContext.updateTemplates();
    toast.success("Template saved successfully");
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip label="Templates" onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem disabled>Use template for this pole:</MenuItem>
        <Divider />
        {summaryContext.templates.map((template) => (
          <MenuItem key={template.id} onClick={() => onTemplateClick(template)}>
            {template.name}
          </MenuItem>
        ))}
        {summaryContext.templates.length > 0 && <Divider />}
        <Link
          to="/options/pole/status/template"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem id="poleStatus">Go to template editor</MenuItem>
        </Link>
        <Divider />
        <MenuItem
          onClick={() => {
            openSaveTemplateDialog();
          }}
        >
          Create new template
        </MenuItem>
      </Menu>
    </>
  );
}
