import { useEffect } from "react";
import { useStateMachineExecutions } from "hooks";
import { useParams } from "react-router-dom";

import ItemColumn from "views/options/pole/status/ItemColumn";
import ItemColumnButton from "views/options/pole/status/ItemColumnButton";

export default function StateMachineExecutions() {
  const params = useParams();

  const stateMachineId = parseInt(params.state_machine_id);
  const executionId = parseInt(params.execution_id);
  const { executions, loadExecutions } =
    useStateMachineExecutions(stateMachineId);

  useEffect(() => {
    loadExecutions(stateMachineId);
  }, [stateMachineId]);

  return (
    <ItemColumn
      title="State Executions"
      description="Each execution triggered via the web app"
      addLink={stateMachineId ? `/batch/${stateMachineId}/execution/new` : null}
      linkReplace
    >
      {executions.map((execution) => {
        return (
          <ItemColumnButton
            linkReplace
            link={
              execution.id === executionId
                ? `/batch/${params.state_machine_id}`
                : `/batch/${params.state_machine_id}/execution/${execution.id}`
            }
            active={execution.id === executionId}
          >
            {execution.name}
          </ItemColumnButton>
        );
      })}
    </ItemColumn>
  );
}
