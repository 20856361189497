import React, { ReactElement } from "react";
import { Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import translations from "translations";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import RoleWrapper from "components/RoleHOC/wrapper";

import "./style.scss";
import { useLanguage } from "hooks";

type Props = {
  infoDialogOpen: boolean;
  setInfoDialogOpen: (boolean) => void;
};

export default function InfoDialogContent({
  infoDialogOpen,
  setInfoDialogOpen,
}: Props): ReactElement {
  const { language } = useLanguage();
  return (
    <Dialog
      onClose={() => setInfoDialogOpen(false)}
      open={infoDialogOpen}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle variant="h3">{translations.MapInfo[language]}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setInfoDialogOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant={"h6"}>
          {translations.MapInfo.Glossary[language]}
        </Typography>
        <Typography>
          <b>{translations.MapInfo.Glossary.Detection[language]}</b> ={" "}
          {translations.MapInfo.Glossary.Detection.Explanation[language]}
        </Typography>
        <Typography>
          <b>{translations.MapInfo.Glossary.Object[language]}</b> ={" "}
          {translations.MapInfo.Glossary.Object.Explanation[language]}
        </Typography>
        <Typography>
          <b>{translations.MapInfo.Glossary.Defect[language]}</b> ={" "}
          {translations.MapInfo.Glossary.Defect.Explanation[language]}
        </Typography>
        <Typography>
          <b>{translations.MapInfo.Glossary.Remark[language]}</b> ={" "}
          {translations.MapInfo.Glossary.Remark.Explanation[language]}
        </Typography>

        <div style={{ marginTop: "30px" }}>
          <Typography variant={"h6"}>
            {translations.MapInfo.WhenNoFiltersAreApplied[language]}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#C2DEFF",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {
                translations.MapInfo.WhenNoFiltersAreApplied.NoDetections[
                  language
                ]
              }
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#75B6FF",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {
                translations.MapInfo.WhenNoFiltersAreApplied.FewerThanSix[
                  language
                ]
              }
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#0077FF",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {
                translations.MapInfo.WhenNoFiltersAreApplied.MoreThanSix[
                  language
                ]
              }
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#B18977",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {translations.MapInfo.WhenNoFiltersAreApplied.Thermal[language]}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#DE0000",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {
                translations.MapInfo.WhenNoFiltersAreApplied.ImageWithDefect[
                  language
                ]
              }
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <Typography variant={"h6"}>
            {translations.MapInfo.WhenTheFilterForRemarksIsApplied[language]}
          </Typography>
          <Typography>
            {
              translations.MapInfo.WhenTheFilterForRemarksIsApplied.Explanation[
                language
              ]
            }
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#FF5634",
                width: "25px",
                height: "25px",
                margin: "5px",
                border: "dotted",
                borderWidth: "1px",
              }}
            ></div>
            <Typography>
              {
                translations.MapInfo.WhenTheFilterForRemarksIsApplied.Remark[
                  language
                ]
              }
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <Typography variant={"h6"}>
            {translations.MapInfo.ColorGuidePowerlines[language]}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#FF0000" />
            <Typography>
              {" "}
              {translations.MapInfo.ColorGuidePowerlines.HasNoImages[language]}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#783F04" />
            <Typography>
              {" "}
              {translations.MapInfo.ColorGuidePowerlines.IsBuried[language]}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#0000FF" />
            <Typography>
              {" "}
              {translations.MapInfo.ColorGuidePowerlines.HasImages[language]}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#D1E3FF" />
            <Typography>
              {" "}
              {
                translations.MapInfo.ColorGuidePowerlines.NeedsReflight[
                  language
                ]
              }
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#FFEF00" />
            <Typography>
              {" "}
              {
                translations.MapInfo.ColorGuidePowerlinesInspectorMode
                  .CriticalAnalysisDone[language]
              }
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShowChartIcon htmlColor="#00FF00" />
            <Typography>
              {" "}
              {
                translations.MapInfo.ColorGuidePowerlines.HasImagesAnalysisDone[
                  language
                ]
              }
            </Typography>
          </div>
        </div>

        <RoleWrapper keyName="colorGuide">
          <div style={{ marginTop: "30px" }}>
            <Typography variant={"h6"}>
              {translations.MapInfo.ColorGuidePowerlinesInspectorMode[language]}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#FF0000" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .HasNoImages[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#783F04" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .IsBuried[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#0000FF" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .HasImages[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#D1E3FF" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .NeedsReflight[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#00FF00" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .SuperUserAnalysed[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#9900FF" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .SupervisorAnalysed[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#FFC1C1" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .AnnotatorAnalysed[language]
                }
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShowChartIcon htmlColor="#FFEF00" />
              <Typography>
                {" "}
                {
                  translations.MapInfo.ColorGuidePowerlinesInspectorMode
                    .CriticalAnalysisDone[language]
                }
              </Typography>
            </div>
          </div>
        </RoleWrapper>
      </DialogContent>
    </Dialog>
  );
}
