import {
  Alert,
  Card,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useStateMachines } from "hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "views/Loading";
import ItemColumn from "views/options/pole/status/ItemColumn";

function fromJsonToString(json: Record<string, unknown>) {
  return JSON.stringify(json)
    ?.replaceAll("{", "{\n  ")
    ?.replaceAll(",", ",\n  ")
    ?.replaceAll("}", "\n}")
    ?.replaceAll(":", ": ");
}

function validateJson(json: string) {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
}

export default function StateMachine() {
  const params = useParams();
  const [nameModified, setNameModified] = useState(false);
  const [descriptionModified, setDescriptionModified] = useState(false);
  const [inputSchemaModified, setInputSchemaModified] = useState(false);
  const [outputSchemaModified, setOutputSchemaModified] = useState(false);
  const [arnModified, setArnModified] = useState(false);

  const [inputValid, setInputValid] = useState(true);
  const [outputValid, setOutputValid] = useState(true);

  const stateMachineId = parseInt(params.state_machine_id);

  const { stateMachines, loading, errorMessage } = useStateMachines();

  const currentStateMachine = stateMachines.find(
    (stateMachine) => stateMachine.id === stateMachineId
  );

  function onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const targetName = e.target.name;
    if (targetName in currentStateMachine) {
      const storedValue = currentStateMachine[targetName];
      const newValue = e.target.value;
      const change = storedValue !== newValue;
      switch (targetName) {
        case "name":
          setNameModified(change);
          break;
        case "description":
          setDescriptionModified(change);
          break;
        case "inputSchema":
          setInputSchemaModified(change);
          setInputValid(validateJson(newValue));
          break;
        case "outputSchema":
          setOutputSchemaModified(change);
          setOutputValid(validateJson(newValue));
          break;
        case "arn":
          setArnModified(change);
          break;
      }
    }
  }

  const anythingModified =
    nameModified ||
    descriptionModified ||
    inputSchemaModified ||
    outputSchemaModified ||
    arnModified;

  return (
    <Stack
      sx={{
        flex: 1,
        padding: 2,
        height: "100%",
        maxWidth: "calc( 33% - 8px )",
        borderRadius: 2,
      }}
    >
      {stateMachineId ? (
        currentStateMachine ? (
          <Card sx={{ background: "white", borderRadius: 2, p: 2 }}>
            <ItemColumn
              title="State Machine"
              description={currentStateMachine.name}
              saveDisabled={!anythingModified || !inputValid || !outputValid}
              onSave={() => {
                console.log("Save");
              }}
            >
              <Divider style={{ marginBottom: "1rem", marginTop: "0.5rem" }} />
              <Stack spacing={3}>
                <TextField
                  defaultValue={currentStateMachine.name}
                  label={nameModified ? "*Name" : "Name"}
                  name="name"
                  fullWidth
                  type="text"
                  onChange={onChange}
                  color="success"
                />
                <TextField
                  defaultValue={currentStateMachine.description}
                  label="Description"
                  name="description"
                  fullWidth
                  multiline
                  onChange={onChange}
                />
                <TextField
                  defaultValue={fromJsonToString(
                    currentStateMachine.inputSchema
                  )}
                  label="Input Schema"
                  name="inputSchema"
                  fullWidth
                  multiline
                  error={!inputValid}
                  onChange={onChange}
                />
                <TextField
                  defaultValue={fromJsonToString(
                    currentStateMachine.outputSchema
                  )}
                  label="Output Schema"
                  name="outputSchema"
                  fullWidth
                  multiline
                  error={!outputValid}
                  onChange={onChange}
                />
                <TextField
                  defaultValue={currentStateMachine.arn}
                  label="ARN"
                  name="arn"
                  fullWidth
                  onChange={onChange}
                />
              </Stack>
            </ItemColumn>
          </Card>
        ) : loading ? (
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Loading color="black" relative size={30} />
            <Typography>Loading</Typography>
          </Stack>
        ) : (
          <Alert severity="error">State machine not found</Alert>
        )
      ) : (
        <Alert severity="info">No machine selected</Alert>
      )}
    </Stack>
  );
}
