import { useContext, useEffect } from "react";
import { Clear } from "@mui/icons-material";
import {
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
  Grid,
} from "@mui/material";

import { useLocation, useSearchParams } from "react-router-dom";
import AddPoleItemPlacementImage from "../AddPoleItemPlacementImage/AddPoleItemPlacementImage";
import { AnnotationContext } from "../provider";
import PlacementImage from "./PlacementImage";

export default function PoleItemPlacementImages() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const placementID = parseInt(searchParams.get("placementID"));

  const annotationState = useContext(AnnotationContext);

  useEffect(() => {
    if (placementID) {
      annotationState.getItemPlacementImages(placementID);
    }
  }, [placementID]);

  return (
    <Dialog
      open={true}
      sx={{ zIndex: 20000 }}
      onClose={() => {
        if (searchParams.has("addPoleItem")) {
          searchParams.delete("addPoleItem");
        }
        if (searchParams.has("box")) {
          searchParams.delete("box");
        }
        if (searchParams.has("placementID")) {
          searchParams.delete("placementID");
        }
        if (searchParams.has("ConnectPlacementImages")) {
          searchParams.delete("ConnectPlacementImages");
        }
        setSearchParams(searchParams);
      }}
      maxWidth={location.pathname.endsWith("add") ? "xl" : "md"}
    >
      <Stack
        sx={{ p: 2 }}
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack spacing={1} minWidth={500}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" component="h2">
              Connected images
            </Typography>

            <IconButton
              onClick={() => {
                if (searchParams.has("ConnectPlacementImages")) {
                  searchParams.delete("ConnectPlacementImages");
                }
                setSearchParams(searchParams);
              }}
            >
              {<Clear />}
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 2, mt: 1 }} />
          <Grid container spacing={2}>
            {annotationState.poleItemPlacementImages.map((image) => (
              <Grid item xs={3}>
                <PlacementImage image={image} />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <AddPoleItemPlacementImage
          boxItems={annotationState.poleItemPlacementImages}
        />
      </Stack>
    </Dialog>
  );
}
