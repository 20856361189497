import { UserDetails } from "interfaces";

export interface IState {
  id: number;
  purposes: string[];
  emailList: { id: number; email: string }[];
  users: UserDetails[];
  annotators: {
    id: number;
    name: string;
    color: string;
  }[];
}

export const initialState: IState = {
  id: -1,
  purposes: [],
  emailList: [],
  users: [],
  annotators: [],
};

const imageReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_MISSION_ID": {
      return {
        ...state,
        id: action.payload,
      };
    }

    case "SET_PURPOSES": {
      return {
        ...state,
        purposes: action.payload,
      };
    }

    case "SET_EMAIL_LIST": {
      return {
        ...state,
        emailList: action.payload,
      };
    }

    case "SET_MISSION_USERS": {
      return {
        ...state,
        users: action.payload,
      };
    }
    case "SET_MISSION_ANNOTATORS": {
      return {
        ...state,
        annotators: action.payload,
      };
    }

    default:
      return state;
  }
};

export default imageReducer;
