import {
  CloseOutlined,
  Flag,
  ModeEditOutlineOutlined,
  NavigateBefore,
  NavigateNext,
  RemoveDone,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Paper, Tooltip, Typography } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { getFilterActive, useDispatch, useSelector } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import {
  approveFalseReviewObjects,
  flagImageForReannotation,
  getFalseReviewObjects,
} from "state/actions";
import { getWrappedEntryInReviewList } from "./utils";

export default function SuperFalseReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // Get data from redux
  const reviewObjects = useSelector((state) => state.objects.falseReview);
  const filteredAnnotations = useSelector((state) => state.image.filteredAnnotations);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const currentImage: number = parseInt(params.image);
  const isMountedRef = useRef(true);

  const filterActive = getFilterActive(searchParams);
  const [lastImage, setLastImage] = useState(-1);

  const goToImageByIndex = useCallback(
    (index: number) => {
      // Check if the component is still mounted before navigating
      if (isMountedRef.current) {
        const imageIdAtIndex = getWrappedEntryInReviewList(reviewObjects, index);

        if(imageIdAtIndex) {
          navigate(`../../${imageIdAtIndex}/superfalsepositive-review${location.search}`);
        }
      }
    },
    // Need to include `reviewObjects` or else it won't be kept up to date when it's updated by a response.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search, reviewObjects]
  );

  let currentIndex = -1;
  if(reviewObjects?.imageIds?.length > 0) {
    currentIndex = reviewObjects.imageIds.findIndex((image) => image === currentImage);

    // If this is still -1, we know we have a list of images & that we aren't in it.
    // Thus we can move to a valid one.
    if (currentIndex === -1) {
      goToImageByIndex(0);
    }
  }

  useEffect(() => {
    // Exists to make sure we only react to reviews on the current image not switching to another image.
    // This is required to make backtracking work.
    if (lastImage === currentImage) {
        const reviewFinished = filteredAnnotations
          .flatMap((o) => o.workflow_status)
          .every((w) => w !== 4);

        if(reviewFinished) {
          goToImageByIndex(currentIndex + 1);
        }
    }

    // Because the filtered annotations aren't updated at the same time as we switch image, this needs to happen here.
    // It's a guard against auto moving you forward when backtracking.
    // Essentially an "ignore the first update to this image because it's us fetching new data".
    setLastImage(currentImage);
    // Not reacting to `currentImage` changes because it will cause a double trigger when `filteredAnnotations` changes which will make you auto move to the next image-.
  }, [filteredAnnotations])

  useEffect(() => {
    dispatch(getFalseReviewObjects());
  }, [filterActive]);

  if (!reviewObjects.imageIds) {
    return <></>
  }

  let newUrl = location.pathname;
  const regex = /\/superfalsepositive-review/gi;
  newUrl = newUrl.replaceAll(regex, "");

  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <RoleWrapper
      keyName="supervisorFalseReview"
      fallback={
        <Paper sx={{ p: 2 }}>
          <Typography>You do not have access to this feature</Typography>
        </Paper>
      }
    >
      <div className="reviewToolWrapper" style={{ marginLeft: "170px" }}>
        <div className="backgroundControll">
          <Tooltip title="Go to previous image" placement="left" arrow>
            <NavigateBefore
              className="navigationArrow"
              fontSize="large"
              sx={{ color: "#006FEB" }}
              onClick={() => goToImageByIndex(currentIndex - 1)}
            />
          </Tooltip>

          <div className="menuWrapper">
            <div className="circleWrapper">
              <div className="circle green">
                <Tooltip
                  title="Verify objects as false positives"
                  placement="top"
                  arrow
                >
                  <RemoveDone
                    htmlColor="white"
                    sx={{ color: "#ffff" }}
                    className="thumb"
                    onClick={() =>
                      dispatch(
                        approveFalseReviewObjects(
                          currentImage,
                          filteredAnnotations.flatMap((o) => o.type_id),
                          () => goToImageByIndex(currentIndex + 1)
                        )
                      )
                    }
                  />
                </Tooltip>
              </div>
              <div className="circle red">
                <Tooltip
                  title="Flag image for reannotation"
                  placement="top"
                  arrow
                >
                  <Flag
                    htmlColor="white"
                    sx={{ color: "#ffff" }}
                    className="thumb"
                    onClick={() =>
                      dispatch(
                        flagImageForReannotation(currentImage, true, () =>
                          goToImageByIndex(currentIndex + 1)
                        )
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
            <div className="subMenuWrapper">
              <Tooltip
                title="Toggle visibility for bounding boxes. Does not change anything on the image"
                placement="bottom"
                arrow
              >
                <VisibilityOutlined
                  sx={{ color: "#ffff" }}
                  onClick={toggleBoundingBox}
                />
              </Tooltip>
              <Tooltip title="Open image editor" placement="bottom" arrow>
                <Link
                  to={`annotate${location.search}`}
                  replace
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ModeEditOutlineOutlined sx={{ color: "#ffff" }} />
                </Link>
              </Tooltip>
              <Tooltip title="Exit review mode" placement="bottom" arrow>
                <CloseOutlined
                  sx={{ color: "#ffff" }}
                  onClick={() => {
                    navigate(`${newUrl}${location.search}`);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <Tooltip title="Go to next image" placement="right" arrow>
            <NavigateNext
              className="navigationArrow green"
              fontSize="large"
              sx={{ color: "#006FEB" }}
              onClick={() => goToImageByIndex(currentIndex + 1)}
            />
          </Tooltip>
        </div>
        <div className="reviewImagesCount">
          {currentIndex + 1}/{reviewObjects.imageIds.length}{reviewObjects.limited ? "+" : ""}
        </div>
      </div>
    </RoleWrapper>
  );
}
