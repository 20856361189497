import Canvas from "./components/Canvas";
import AnnotationsList from "./components/AnnotationsList";
import { AnnotationProvider, CanvasProvider } from "./provider";
import { useParams } from "react-router-dom";
import NavBar from "./components/NavBar";
import ListNavBar from "./components/ListNavBar";

export default function AnnotationTool() {
  const pathParams = useParams();
  const imageID = parseInt(pathParams.image);

  return (
    <CanvasProvider>
      <AnnotationProvider imageID={imageID}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "370px 1fr",
            gridTemplateRows: "46px 1fr",
            height: "100%",
            background: "rgba(255,255,255,0.7)",
          }}
        >
          <div style={{ gridArea: "1 / 1 / 2 / 2" }}>
            <ListNavBar />
          </div>
          <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
            <NavBar />
          </div>
          <div
            style={{
              gridArea: "2 / 1 / 3 / 2",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <AnnotationsList />
          </div>
          <div style={{ gridArea: "2 / 2 / 3 / 3", overflow: "hidden" }}>
            <Canvas />
          </div>
        </div>
      </AnnotationProvider>
    </CanvasProvider>
  );
}
