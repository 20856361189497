import { useContext } from "react";
import { context as BatchManagerContext } from "providers/BatchManagerProvider";

export function useStateMachineExecutions(stateMachineId: number) {
  const {
    executions,
    loading,
    errorMessage,
    loadExecutions,
    setErrorMessage,
    startExecution,
  } = useContext(BatchManagerContext);

  const filteredExecutions = executions.filter(
    (execution) => execution.state_machine_id === stateMachineId
  );

  return {
    executions: filteredExecutions,
    loading,
    errorMessage,
    loadExecutions,
    setErrorMessage,
    startExecution,
  };
}
