import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear, FactCheckOutlined } from "@mui/icons-material";
import { CanvasContext } from "../provider";

export default function NoneDefectVerificationButton() {
  const { mode, setMode } = useContext(CanvasContext);
  const addMode = mode === "noneDefectVerification";
  return (
    <Tooltip
      title={
        addMode
          ? "Leave mark objects without defects mode"
          : "Enter mark objects without defects mode"
      }
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton
        onClick={() => setMode(addMode ? "view" : "noneDefectVerification")}
        style={{ width: "42px", height: "42px" }}
      >
        {mode === "noneDefectVerification" ? (
          <Clear />
        ) : (
          <FactCheckOutlined style={{ width: "27px", height: "27px" }} />
        )}
      </IconButton>
    </Tooltip>
  );
}
