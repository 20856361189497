import { IssueSeverity, ObjectType } from "interfaces";
import { useLocation } from "react-router-dom";
import { getClientObjectTypeName } from "utils/utils";
import InfoContent from "../InfoContent";
import ColorBox from "./ColorBox";
import InfoBox from "./InfoBox";
import { IImageAnnotation } from "views/AnnotationTool/api";

type Props = {
  annotation: IImageAnnotation;
  iw: number;
  ih: number;
  objectTypes: ObjectType[];
  issueSeverities: IssueSeverity[];
  lockedRegion: null | string;
  hoveredRegion: null | string;
  selectedRegions: string[];
  fixableDefects: boolean;
  setGetData;
  skyqraft_employee: boolean;
  annotatorObjectColor: boolean;
};

export default function Region({
  annotation,
  iw,
  ih,
  objectTypes,
  issueSeverities,
  lockedRegion,
  hoveredRegion,
  selectedRegions,
  fixableDefects,
  setGetData,
  skyqraft_employee,
  annotatorObjectColor,
}: Props) {
  const location = useLocation();

  // We must have a type to render a region
  if (annotation.types.length === 0) {
    return null;
  }

  const falseReviewOpen = location.pathname.includes(
    "/superfalsepositive-review"
  );

  const types = [];
  const severities = [];
  const objectTypesNew = objectTypes.map((e) => getClientObjectTypeName(e.id));

  for (let i = 0; i < annotation.types.length; i++) {
    const t = annotation.types[i];
    const type = objectTypesNew.find((ot) => ot.id === t);
    const severity = issueSeverities.find(
      (ot) => ot.id === annotation.severities[i]
    );

    // Skip if we didn't find a type
    if (!type) {
      continue;
    }

    // Filter out any false positive
    if (
      !falseReviewOpen &&
      (annotation.workflow_status[i] === 4 ||
        annotation.workflow_status[i] === 5)
    ) {
      continue;
    }

    // Checking for non-skyqraft users
    if (!skyqraft_employee) {
      // Remove if severity is skyqraft only
      if (!!severity && severity.skyqraft_only) {
        continue;
      }

      // Remove if type is skyqraft only
      if (!!type && type.skyqraft_only) {
        continue;
      }

      // Remove defects that haven't been approved
      if (!!severity && annotation.workflow_status[i] === 1) {
        continue;
      }
    }

    // Allow the type to pass
    types.push(t);
    severities.push(annotation.severities[i]);
  }

  const primaryType = objectTypesNew.find((o) => o.id === types[0]);

  // We must have a valid type
  if (!primaryType || types.length === 0) {
    return null;
  }

  const x = annotation.x * iw;
  const y = annotation.y * ih;
  const w = annotation.w * iw;
  const h = annotation.h * ih;

  const isDefect = annotation.workflow_status.some(
    (e, i) => !!annotation.severities[i] && e !== 4
  );

  return (
    <g>
      <ColorBox
        x={x}
        y={y}
        w={w}
        h={h}
        iw={iw}
        className={`target object object-${annotation.id}`}
        color={
          annotatorObjectColor ? primaryType.class_color : primaryType.color
        }
        dashed={!!annotation.fixed}
        selected={selectedRegions.includes(annotation.id)}
        isDefect={isDefect}
        rotation={annotation.rotation}
      />

      <InfoBox
        x={x}
        y={y}
        w={w}
        h={h}
        onClick={() => {}}
        onHover={() => {}}
        open={lockedRegion === annotation.id || hoveredRegion === annotation.id}
        rotation={annotation.rotation}
      >
        <InfoContent
          annotation={{ ...annotation, types, severities }}
          fixableDefects={fixableDefects}
          setGetData={setGetData}
        />
      </InfoBox>
    </g>
  );
}
