import { IReviewObjectList } from "state/reducers/objectreducer";

/**
* Returns the image id at the index or `undefined` if the list is empty.
*/
export function getWrappedEntryInReviewList(list: IReviewObjectList, index: number) {
    // Wrap the index to simplify moving to the next and previous at the ends.
    // If you are at the end of the review list, this makes sure you are put back at the start if you try to go the next.
    // as well as the reverse at the start.
    // I.e in a list with 3 entries. Trying to go to index 3 puts you at 3 % 3 == 0.
    // Trying to go to -1 puts you at (-1 + 3) % 3 == 2.
    const wrappedIndex =
      (index + list.imageIds.length) %
      list.imageIds.length;

    const imageIdAtIndex = list.imageIds[wrappedIndex];

    return imageIdAtIndex;
  }

