import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

type Props = {
  storageKey: string;
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  expandToMaxWidth?: boolean;
  children: ReactNode;
};

export default function SessionStoragePopupWindow({
  storageKey,
  title,
  maxWidth,
  expandToMaxWidth,
  children,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  // Check if the popup has been shown before
  useEffect(() => {
    const isPopup = localStorage.getItem(storageKey);
    if (!isPopup) {
      setIsOpen(true);
    }
  }, []);

  // Handle close of the popup
  function handleClose() {
    setIsOpen(false);
    localStorage.setItem(storageKey, "true");
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={expandToMaxWidth}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
