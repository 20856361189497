import { useState, useEffect, ReactNode, createContext } from "react";
import {
  getStateMachines,
  getStateMachineExecutions,
  createStateMachine,
  startStateMachineExecution,
} from "api/batch";

// Unwrap the type of the state machine after promise has been resolved

type IStateMachines = Awaited<ReturnType<typeof getStateMachines>>;
type IStateMachineExecution = Awaited<
  ReturnType<typeof getStateMachineExecutions>
>;

export interface IContextValue {
  stateMachines: IStateMachines;
  executions: IStateMachineExecution;
  loading: boolean;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
  loadExecutions: (stateMachineId: number) => void;
  loadStateMachines: () => void;
  addStateMachine: (
    stateMachine: Omit<IStateMachines[number], "id" | "created" | "creator">
  ) => Promise<void>;
  startExecution: (
    stateMachineId: number,
    name: string,
    input: Record<string, unknown>
  ) => Promise<void>;
}

const context = createContext<IContextValue>({
  stateMachines: [],
  executions: [],
  errorMessage: null,
  loading: false,
  setErrorMessage: () => {},
  loadExecutions: () => {},
  loadStateMachines: () => {},
  addStateMachine: () => Promise.resolve(),
  startExecution: () => Promise.resolve(),
});

interface IProviderProps {
  children: ReactNode;
}

function BatchManagerProvider({ children }: IProviderProps) {
  // Track api behaviour
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Track state machines
  const [stateMachines, setStateMachines] = useState<IStateMachines>([]);
  const [executions, setExecutions] = useState<IStateMachineExecution>([]);

  function setError(error: Error) {
    setErrorMessage(error.message);
  }
  function clearLoading() {
    setLoading(false);
  }

  function loadStateMachines() {
    setLoading(true);
    getStateMachines()
      .then(setStateMachines)
      .catch(setError)
      .finally(clearLoading);
  }

  function loadExecutions(stateMachineId: number) {
    if (!stateMachineId) {
      return;
    }
    setLoading(true);
    getStateMachineExecutions(stateMachineId)
      .then(setExecutions)
      .catch(setError)
      .finally(clearLoading);
  }

  async function addStateMachine(
    stateMachine: Omit<IStateMachines[number], "id" | "created" | "creator">
  ) {
    setLoading(true);
    try {
      await createStateMachine(stateMachine);
      loadStateMachines();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }

  async function startExecution(
    stateMachineId: number,
    name: string,
    input: Record<string, unknown>
  ) {
    setLoading(true);
    try {
      await startStateMachineExecution(stateMachineId, name, input);
      loadExecutions(stateMachineId);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }

  // Get the state machines from the API when loading
  useEffect(() => {
    loadStateMachines();
  }, []);

  // Create the payload to let
  // consumers know what the last URL was
  const payload: IContextValue = {
    stateMachines,
    executions,
    errorMessage,
    setErrorMessage,
    loadExecutions,
    loadStateMachines,
    loading,
    addStateMachine,
    startExecution,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default BatchManagerProvider;

export { context };
