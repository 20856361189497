import { useContext } from "react";
import { Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import {
	Link,
	useLocation,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { AnnotationContext } from "../provider";
import { getCDNURL } from "authentication/apiDetails";

export default function PlacementImage({ image }) {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const poleID = parseInt(searchParams.get("pole"));
	const projectID = parseInt(params.mission);
	const placementID = parseInt(searchParams.get("placementID"));

	const annotationState = useContext(AnnotationContext);

	function deleteItem(itemID: number) {
		annotationState.deleteItemPlacementImage(poleID, placementID, itemID);
	}

	return (
		<Box sx={{ width: "100%", maxWidth: 100, position: "relative" }}>
			<Link to={`/${projectID}/${image.id}${location.search}`}>
				<img
					src={
						image.src.length < 4
							? "/sample.JPG"
							: encodeURI(`${getCDNURL()}/image/${image.src}`)
					}
					alt="imageItem"
					style={{ width: "100%" }}
				/>
			</Link>
			<IconButton
				sx={{
					position: "absolute",
					width: 22,
					height: 22,
					top: 5,
					right: 5,
					background: "rgba(0,0,0,0.3)",
					"&:hover": {
						background: "rgba(0,0,0,0.7)",
					},
				}}
				onClick={() => {
					deleteItem(image.id);
				}}
			>
				<Delete sx={{ width: 18 }} htmlColor="white" />
			</IconButton>
		</Box>
	);
}
