import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertPositionToPercentage, getBoxFromPoints } from "../utils";
import { getCanvasBox } from "./utils";
import { useSelector } from "hooks";

interface IProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  setCurrentBox: Dispatch<SetStateAction<IBox>>;
}

interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface IPoint {
  x: number;
  y: number;
}

export function useNewBoxListener({ canvasRef, setCurrentBox }: IProps) {
  const userName = useSelector((state) => state.user.name);
  const [startPoint, setStartPoint] = useState<IPoint | null>(null);
  const context = useContext(AnnotationContext);
  const { matrix, setMode, mode, imageDimensions } = useContext(CanvasContext);

  const foundType = context.objectTypes.find(
    (obj) => context.savedTypes === obj.id
  );
  let typeID: number | null = null;

  if (mode === "addDetection") {
    if (foundType?.issue === false) {
      typeID = foundType?.id;
    } else {
      typeID = null;
    }
  } else {
    if (foundType?.issue === true) {
      typeID = foundType?.id;
    } else {
      typeID = null;
    }
  }

  if (mode === "addSteelwork") {
    typeID = null;
  }

  function resetListener() {
    setStartPoint(null);
    setCurrentBox({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });
  }

  function addNewAnnotation({
    x,
    y,
    w,
    h,
  }: {
    x: number;
    y: number;
    w: number;
    h: number;
  }) {
    context.setAnnotations([
      ...context.annotations,
      {
        id: `n${w}`,
        x,
        y,
        w,
        h,
        origin: 1,
        fixed: null,
        reported: null,
        ring_count: null,
        processed: [null],
        types:
          mode === "addSteelwork" ? [context.steelworkObjectTypeId] : [typeID],
        severities: [null],
        workflow_status: [2],
        skyqraft_hidden: [false],
        objectHasNoDefect: false,
        type_comment: [""],
        type_id: [null],
        confidence: [1],
        active: true,
        is_defect:
          mode === "addDetection" || mode === "addSteelwork" ? [false] : [true],
        categories: [null],
        client_types: [undefined],
        highlighted: true,
        visible: true,
        editIndex: null,
        editType: null,
        hover: false,
        rotation: 0,
        item_id: null,
        isLocked: false,
        creator: [userName],
        updated_by: [userName],
      },
    ]);
  }

  useGesture(
    {
      onDrag: (state) => {
        if (!canvasRef.current) return;
        if (!startPoint) return;
        if (startPoint.x < 0) return;
        if (startPoint.y < 0) return;
        if (startPoint.x > 1) return;
        if (startPoint.y > 1) return;
        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { top, left } = getCanvasBox(canvasRef);

        const newPoint = convertPositionToPercentage(
          top,
          left,
          imageDimensions,
          clientX,
          clientY,
          matrix
        );

        const { x, y } = startPoint;
        const newBox = getBoxFromPoints({ x, y }, newPoint);
        setCurrentBox(newBox);
      },
      onDragEnd: (state) => {
        if (!canvasRef.current) return;
        if (!startPoint) return;
        if (startPoint.x < 0) return;
        if (startPoint.y < 0) return;
        if (startPoint.x > 1) return;
        if (startPoint.y > 1) return;
        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { top, left } = getCanvasBox(canvasRef);
        const newPoint = convertPositionToPercentage(
          top,
          left,
          imageDimensions,
          clientX,
          clientY,
          matrix
        );
        const { x, y } = startPoint;
        const newBox = getBoxFromPoints({ x, y }, newPoint);
        addNewAnnotation({
          x: newBox.x,
          y: newBox.y,
          w: newBox.width,
          h: newBox.height,
        });
        resetListener();

        setTimeout(() => {
          setMode("view");
        }, 100);
      },

      onDragStart: (state) => {
        // Start the creation of a box
        if (!canvasRef.current) return;

        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { left, top } = getCanvasBox(canvasRef);

        // Check the current location of the mouse
        // in terms of canvas percentages
        const { x, y } = convertPositionToPercentage(
          top,
          left,
          imageDimensions,
          clientX,
          clientY,
          matrix
        );

        // Save starting point to local state
        setStartPoint({
          x,
          y,
        });
      },
    },
    {
      target: canvasRef,
      drag: {
        enabled:
          mode === "addDefect" ||
          mode === "addDetection" ||
          mode === "addSteelwork",
      },
    }
  );
}
