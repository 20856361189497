import { useState, useEffect, useContext, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { PlayArrow, Stop, Menu as MenuIcon, Delete } from "@mui/icons-material";
import { context as WorkSessionContext } from "providers/WorkSessionProvider";
import { useCurrentProject } from "hooks";
import { round } from "utils";

export default function WorkSession() {
  const {
    workSessions,
    tags,
    startSession,
    stopSession,
    currentSession,
    updateSession,
    description,
    selectedTags,
    timeElapsed,
    setCurrentSession,
    setSelectedTags,
    setDescription,
    setTimeElapsed,
    deleteSession,
  } = useContext(WorkSessionContext);
  const tagsAnchor = useRef(null);
  const currentProject = useCurrentProject();
  const [disabled, setDisabled] = useState(false);
  const [historyOpen, setOpenHistory] = useState(false);
  const [tagsEditTarget, setTagsEditTarget] = useState<number | null>(null);

  let timeStart = null;
  if (currentSession) {
    timeStart = Date.parse(currentSession.start_time);
  }
  const running = currentSession && currentSession.end_time === null;

  const onStart = async () => {
    const timeInISO = new Date().toISOString();
    await startSession(description, selectedTags, timeInISO, null);
  };

  function openHistory() {
    setOpenHistory(true);
  }

  function addTagToSession(tagId: number) {
    setSelectedTags([tagId]);
    if (currentSession) {
      updateSession(
        currentSession.id,
        description,
        [tagId],
        undefined,
        undefined
      );
    }
  }

  async function onStop() {
    if (currentSession) {
      await stopSession(currentSession.id);
    }
  }

  // Update the description when the current session changes
  useEffect(() => {
    if (currentSession) {
      setDescription(currentSession.description);
    } else {
      setDescription("");
    }
    if (currentSession?.tags?.length) {
      setSelectedTags(currentSession.tags.map((tag) => tag.id));
    }
  }, [currentSession]);

  // Update time elapsed once per second
  useEffect(() => {
    if (running) {
      const interval = setInterval(() => {
        setTimeElapsed(Date.now() - timeStart);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [running, timeStart]);

  function onDescriptionChange(e) {
    setDescription(e.target.value);
    if (!currentSession) return;
    if (e.target.value === "") {
      updateSession(
        currentSession.id,
        undefined,
        undefined,
        undefined,
        undefined
      );
    } else {
      updateSession(
        currentSession.id,
        e.target.value,
        undefined,
        undefined,
        undefined
      );
    }
  }

  // Make sure hours, minutes and seconds are always two digits or more
  const hours = Math.floor(timeElapsed / 3600000)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeElapsed % 3600000) / 60000)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((timeElapsed % 60000) / 1000)
    .toString()
    .padStart(2, "0");

  return (
    <Box
      sx={{
        position: "absolute",
        top: 52,
        height: 45,
        width: "100%",
        backgroundColor: running
          ? "rgba(158, 225, 255, 0.85)"
          : "rgba(255, 158, 158, 0.85)",
        transition: "background-color 0.2s",
        zIndex: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        height="100%"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <IconButton
            disabled={selectedTags.length === 0 || disabled}
            onClick={() => {
              setDisabled(true);
              if (!running) {
                onStart()
                  .catch((e) => console.error(e))
                  .finally(() => setDisabled(false));
              } else {
                onStop()
                  .catch((e) => console.error(e))
                  .finally(() => setDisabled(false));
              }
            }}
          >
            {running ? <Stop /> : <PlayArrow />}
          </IconButton>
          <Box sx={{ width: 400 }}>
            <TextField
              placeholder="Description..."
              value={description}
              onChange={onDescriptionChange}
              variant="standard"
              fullWidth
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Tooltip
            title={
              <Stack>
                <MenuItem color="white">
                  Annotation Inspection:{" "}
                  {round(
                    (currentSession?.annotator_inspected_length ?? 0) / 1000,
                    1
                  )}{" "}
                  km
                </MenuItem>
                <MenuItem color="white">
                  Supervisor Inspection:{" "}
                  {round((currentSession?.inspected_length ?? 0) / 1000, 1)} km
                </MenuItem>
                <MenuItem color="white">
                  Critical Inspection:{" "}
                  {round(
                    (currentSession?.critical_inspected_length ?? 0) / 1000,
                    1
                  )}{" "}
                  km
                </MenuItem>
                <MenuItem color="white">
                  Superuser Inspection:{" "}
                  {round(
                    (currentSession?.confirmed_inspected_length ?? 0) / 1000,
                    1
                  )}{" "}
                  km
                </MenuItem>
              </Stack>
            }
          >
            <Chip
              label={`${round(
                (currentSession
                  ? currentSession.annotator_inspected_length +
                    currentSession.confirmed_inspected_length +
                    currentSession.critical_inspected_length +
                    currentSession.inspected_length
                  : 0) / 1000,
                1
              )} km`}
            />
          </Tooltip>
          <Chip
            label={`Project ${
              currentSession?.project_id ?? currentProject?.id
            }`}
          />
          <IconButton
            onClick={(e) => {
              setTagsEditTarget(currentSession?.id);
              tagsAnchor.current = e.currentTarget;
            }}
          >
            {selectedTags.length ? (
              <Stack>
                {selectedTags.map((tagId) => {
                  const tag = tags.find((tag) => tag.id === tagId);
                  if (!tag) return null;
                  else {
                    return <Chip key={`tag-${tag.id}`} label={tag?.name} />;
                  }
                })}
              </Stack>
            ) : (
              <Typography>No tags...</Typography>
            )}
          </IconButton>
          <Typography sx={{ width: 70, textAlign: "right" }}>
            {hours}:{minutes}:{seconds}
          </Typography>
          <IconButton onClick={openHistory}>
            <MenuIcon />
          </IconButton>
        </Stack>
      </Stack>
      {currentSession?.project_id &&
        currentProject?.id !== currentSession.project_id && (
          <Alert
            color="error"
            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            You have an active session on a different project. If you are
            working on this project, please stop the current session and start a
            new session on this project.
            <Button
              variant="outlined"
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                onStop()
                  .catch((e) => console.error(e))
                  .finally(() => setDisabled(false));
              }}
              sx={{ m: 0, p: 0, ml: 2 }}
              color="error"
            >
              Stop
            </Button>
          </Alert>
        )}
      <Menu
        open={Boolean(tagsAnchor.current)}
        onClose={() => {
          tagsAnchor.current = null;
          setTagsEditTarget(null);
        }}
        anchorEl={tagsAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {tags.map((tag) => (
          <MenuItem
            key={`tag-${tag.id}`}
            onClick={() => {
              tagsAnchor.current = null;
              setTagsEditTarget(null);
              if (tagsEditTarget === null || currentSession === null) {
                addTagToSession(tag.id);
              } else {
                updateSession(
                  tagsEditTarget,
                  undefined,
                  [tag.id],
                  undefined,
                  undefined
                );
              }
            }}
          >
            {tag.name}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        open={historyOpen}
        onClose={() => setOpenHistory(false)}
        maxWidth={"xl"}
      >
        <DialogTitle>Work sessions</DialogTitle>
        <Stack px={2} width={1200} divider={<Divider />}>
          {!workSessions.length ? (
            <Typography>No work sessions found</Typography>
          ) : (
            workSessions
              .sort((a, b) => {
                const startDate = new Date(a.start_time);
                const endDate = new Date(b.start_time);
                const start = startDate.getTime();
                const end = endDate.getTime();
                return end - start;
              })
              .map((session) => {
                const active = session.end_time === null;
                const start = new Date(session.start_time);
                const end = new Date(session.end_time);
                const startMonthAsString = start.toLocaleString("default", {
                  month: "short",
                });
                const startDay = start.getDate().toString().padStart(2, "0");
                const startHours = start.getHours().toString().padStart(2, "0");
                const startMinutes = start
                  .getMinutes()
                  .toString()
                  .padStart(2, "0");

                const endMonthAsString = end.toLocaleString("default", {
                  month: "short",
                });
                const endDay = end.getDate().toString().padStart(2, "0");
                const endHours = end.getHours().toString().padStart(2, "0");
                const endMinutes = end.getMinutes().toString().padStart(2, "0");

                return (
                  <Stack
                    key={`session-${session.id}`}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    px={2}
                    py={1}
                    sx={{
                      background: active
                        ? "rgba(158, 225, 255, 0.85)"
                        : "rgba(158,255,158,0.85)",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {active ? (
                        <Button
                          variant="outlined"
                          color="error"
                          disabled={disabled}
                          onClick={() => {
                            setDisabled(true);
                            stopSession(session.id)
                              .then(() => {
                                setTimeElapsed(0);
                                setDescription("");
                                setCurrentSession(null);
                                setSelectedTags([]);
                              })
                              .catch((e) => console.error(e))
                              .finally(() => setDisabled(false));
                          }}
                        >
                          Stop
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled={disabled}
                          onClick={() => {
                            setDisabled(true);
                            startSession(
                              session.description,
                              session.tags.map((tag) => tag.id),
                              new Date().toISOString(),
                              null
                            )
                              .catch((e) => console.error(e))
                              .finally(() => setDisabled(false));
                          }}
                        >
                          Start
                        </Button>
                      )}
                      <Box sx={{ width: 400 }}>
                        <TextField
                          fullWidth
                          placeholder="Description..."
                          value={session.description}
                          onChange={(e) => {
                            updateSession(
                              session.id,
                              e.target.value,
                              undefined,
                              undefined,
                              undefined
                            );
                          }}
                          variant="standard"
                        />
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Chip label={`Project ${session.project_id}`} />
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTagsEditTarget(session.id);
                          tagsAnchor.current = e.currentTarget;
                        }}
                      >
                        {session.tags.length ? (
                          session.tags.map((tag) => (
                            <Chip key={`tag-${tag.id}`} label={tag.name} />
                          ))
                        ) : (
                          <Chip label="No tag" />
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        divider={
                          <Typography
                            sx={{ fontFamily: "monospace!important" }}
                          >
                            -
                          </Typography>
                        }
                      >
                        <Typography
                          width={130}
                          sx={{
                            fontFamily: "monospace!important",
                            textAlign: "right",
                          }}
                        >
                          {startDay} {startMonthAsString} {startHours}:
                          {startMinutes}
                        </Typography>
                        {!active ? (
                          <Typography
                            width={130}
                            sx={{ fontFamily: "monospace!important" }}
                          >
                            {endDay} {endMonthAsString} {endHours}:{endMinutes}
                          </Typography>
                        ) : (
                          <Typography
                            width={130}
                            sx={{ fontFamily: "monospace!important" }}
                          >
                            Now
                          </Typography>
                        )}
                      </Stack>
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          setDisabled(true);
                          let confirmation = false;
                          try {
                            let sessionTotalTime =
                              end.getTime() - start.getTime();
                            if (session.end_time === null) {
                              sessionTotalTime = Date.now() - start.getTime();
                            }
                            const totalHours = Math.floor(
                              sessionTotalTime / 3600000
                            );
                            const hours = Math.floor(
                              sessionTotalTime / 3600000
                            ).toString();
                            const totalMinutes = Math.floor(
                              (sessionTotalTime % 3600000) / 60000
                            );
                            const minutes = Math.floor(
                              (sessionTotalTime % 3600000) / 60000
                            ).toString();
                            let confirmationMessage =
                              "Are you sure you want to delete the session?";
                            if (totalHours > 0 && totalMinutes !== 0) {
                              confirmationMessage = `Are you sure you want to delete the session with ${hours} hours and ${minutes} of work?`;
                            } else if (totalHours === 0 && totalMinutes !== 0) {
                              confirmationMessage = `Are you sure you want to delete the session with ${minutes} minutes of work?`;
                            } else if (totalHours > 0 && totalMinutes === 0) {
                              confirmationMessage = `Are you sure you want to delete the session with ${hours} hours of work?`;
                            }
                            confirmation = window.confirm(confirmationMessage);
                          } catch (e) {
                            console.log(e);
                          }
                          if (confirmation) {
                            deleteSession(session.id)
                              .catch(console.error)
                              .finally(() => {
                                setDisabled(false);
                              });
                          } else {
                            setDisabled(false);
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                );
              })
          )}
        </Stack>
        <DialogActions>
          <Button onClick={() => setOpenHistory(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
