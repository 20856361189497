import { Clear, ImageNotSupportedOutlined } from "@mui/icons-material";
import MenuItem from "components/MenuItem";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useSearchParams } from "react-router-dom";

import { useLanguage } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import translations from "translations";

const KEY = "bbox";
const ENABLED_VALUE = "0";

export default function SupervisorReview() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const isFalsePositiveReview = location.pathname.includes(
    "/falsepositive-review"
  );

  const [searchParams] = useSearchParams();

  return (
    <RoleWrapper keyName="falsePositiveReview">
      <MenuItem
        id="image-review-false-positive"
        tooltip={translations.ImageViewer.FalsePositiveReview[language]}
        onClick={() => {
          searchParams.delete(KEY);
          if (isFalsePositiveReview) {
            navigate(`.${location.search.replace("&bbox=0", "")}`);
          } else {
            searchParams.append(KEY, ENABLED_VALUE);
            navigate(`falsepositive-review?${searchParams}`);
          }
        }}
      >
        {isFalsePositiveReview ? (
          <Clear htmlColor="red" />
        ) : (
          <ImageNotSupportedOutlined />
        )}
      </MenuItem>
    </RoleWrapper>
  );
}
