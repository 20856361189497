import { Clear, InfoOutlined, Save } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Typography,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { authorizedPut } from "utils/request";

type Props = {};

export default function EditCustomerPage({}: Props) {
  const { updateGroups, group = null } = useOutletContext<{
    updateGroups;
    group;
  }>();

  const [updatedName, setUpdatedName] = React.useState<string>(group?.name);
  const [updatedDefaultExternalID, setUpdatedDefaultExternalID] =
    React.useState<string>(group?.default_external_id);

  React.useEffect(() => {
    resetData();
  }, [group?.name, group?.default_external_id]);

  function resetData() {
    setUpdatedName(group?.name);
    setUpdatedDefaultExternalID(group?.default_external_id ?? "");
  }
  function saveData() {
    let updateDict = {};
    if (updatedName !== group.name) {
      updateDict["name"] = updatedName;
    }
    if (updatedDefaultExternalID !== group.default_external_id) {
      updateDict["default_external_id"] = updatedDefaultExternalID;
    }
    authorizedPut(`/admin/group/${group.id}`, updateDict).then(() => {
      toast.success("Group updated");
      updateGroups();
    });
  }

  return (
    !!group && (
      <Paper sx={{ p: 2, background: "#F7F7F7", position: "relative" }}>
        <Typography variant="h5" component="h2">
          Group Settings - {group?.name ?? "No group"}
        </Typography>
        <Typography component="h3">
          Edit settings on a group level that affects all projects and regions
          within.
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Typography width={150}>Name</Typography>
          <TextField
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
            variant="standard"
            sx={{ flex: 1 }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Typography width={150}>Default External ID</Typography>
          <TextField
            value={updatedDefaultExternalID}
            onChange={(e) => setUpdatedDefaultExternalID(e.target.value)}
            variant="standard"
            sx={{ flex: 1 }}
          />
          <Tooltip
            title="The default external ID is used when exporting reports. If the 'client_id' of the pole is missing, then this default value will be used instead."
            placement="right"
            arrow
            disableInteractive
          >
            <InfoOutlined />
          </Tooltip>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <IconButton
            disabled={
              updatedName === group.name &&
              updatedDefaultExternalID == (group.default_external_id ?? "")
            }
            onClick={resetData}
          >
            <Clear />
          </IconButton>
          <IconButton
            disabled={
              updatedName === group.name &&
              updatedDefaultExternalID == (group.default_external_id ?? "")
            }
            onClick={saveData}
          >
            <Save />
          </IconButton>
        </Stack>
      </Paper>
    )
  );
}
