import { useStateMachines } from "hooks";
import { useParams } from "react-router-dom";
import ItemColumn from "views/options/pole/status/ItemColumn";
import ItemColumnButton from "views/options/pole/status/ItemColumnButton";

export default function StateMachines() {
  const params = useParams();
  const currentStateMachineId = parseInt(params.state_machine_id);
  const currentExecutionId = parseInt(params.execution_id);
  const { stateMachines } = useStateMachines();

  return (
    <ItemColumn
      title="State Machines"
      description="State machines to run in the cloud"
      addLink="/batch/new"
      linkReplace
    >
      {stateMachines.map((stateMachine) => (
        <ItemColumnButton
          link={
            stateMachine.id === currentStateMachineId && !currentExecutionId
              ? "/batch"
              : `/batch/${stateMachine.id}`
          }
          linkReplace
          active={stateMachine.id === currentStateMachineId}
        >
          {stateMachine.name}
        </ItemColumnButton>
      ))}
    </ItemColumn>
  );
}
