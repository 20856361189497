import React from "react";
import { IssueCategory, IssueSeverity, ObjectType } from "interfaces";
import ApproveSelector from "./ApproveSelector";
import HideSelector from "./HideSelector";
import RejectSelector from "./RejectSelector";
import "./style.scss";

type Props = {
  regionID: number;
  type: number;
  skyqraft_hidden: boolean;
  workflow_status: number;
  issueCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  objectTypes: ObjectType[];
  confidence: any;
};

export default function ObjectTypeML({
  regionID,
  type,
  skyqraft_hidden,
  workflow_status,
  objectTypes,
  confidence,
}: Props) {
  const objectType = objectTypes.find((o) => o.id === type);
  return (
    <>
      <div className="supervisorButtons">
        <ApproveSelector
          objectType={objectType}
          workflowStatus={workflow_status}
          regionID={regionID}
          skyqraft_hidden={skyqraft_hidden}
        />
        <HideSelector
          objectType={objectType}
          workflowStatus={workflow_status}
          regionID={regionID}
          skyqraft_hidden={skyqraft_hidden}
        />
        <RejectSelector
          objectType={objectType}
          workflowStatus={workflow_status}
          regionID={regionID}
        />
      </div>
      <p style={{ margin: 0, paddingLeft: 5 }}>{confidence}</p>
    </>
  );
}
