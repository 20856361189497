import { Box, Button, Stack, Typography } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authorizedGet } from "utils/request";
import { AnnotationContext } from "../provider";
import { handleImageSelect } from "../utils/multiSelectImages";
import { sortImages } from "utils/utils";
import { getCDNURL } from "authentication/apiDetails";

interface IImage {
	id: number;
	src: string;
	name: string;
	compass_dir: number;
}

export default function AddPoleItemPlacementImage({ boxItems }) {
	const context = useContext(AnnotationContext);
	const [searchParams] = useSearchParams();
	const poleID = Number.parseInt(searchParams.get("pole"));
	const placementID = Number.parseInt(searchParams.get("placementID"));
	const [imageID, setImageID] = useState<number[]>([]);
	const [images, setImages] = useState<IImage[]>([]);
	const [sortedImages, setSortedImages] = useState<IImage[]>([]);
	const [lastSelectedImageIndex, setLastSelectedImageIndex] =
		useState<number>(-1);

	async function connectImage(imageID: number) {
		await context.addItemPlacementImage(poleID, placementID, imageID);
	}

	useEffect(() => {
		authorizedGet<{ images: IImage[] }>(`/poles/${poleID}/images`)
			.then(({ images }) => {
				setImages(images);
			})
			.catch(() => {
				console.log("No Cluster found");
			});
	}, [poleID, setImages]);

	useEffect(() => {
		if (images.length > 0) {
			const sorted = sortImages(images);
			setSortedImages(sorted);
		}
	}, [images]);

	const handleImageActive = (id: number) => {
		if (imageID.includes(id)) {
			return "clicked";
		}
		if (boxItems.some((item) => item.id === id)) {
			return "active";
		}
		return "notactive";
	};

	const handleColor = (id: number) => {
		const handleImageActiveResponse = handleImageActive(id);
		if (handleImageActiveResponse === "clicked") {
			return "rgba(71, 160, 255, 0.2)";
		}
		if (handleImageActiveResponse === "active") {
			return "rgba(0,0,0,0.1)";
		}
		return "white";
	};

	const handleSubmit = () => {
		for (const id of imageID) {
			connectImage(id);
		}
		setImageID([]);
	};

	const handleClear = () => {
		setImageID([]);
		setLastSelectedImageIndex(-1);
	};

	return (
		<Stack spacing={2}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography>Select images</Typography>

				<Button
					variant="outlined"
					size="small"
					startIcon={<RestartAlt />}
					onClick={() => {
						handleClear();
					}}
				>
					Reset selection
				</Button>
			</Stack>
			<Stack spacing={1}>
				{sortedImages.map((image, index) => (
					<Box
						sx={{
							width: 300,
							pt: 1,
							px: 1,
							pb: 0.5,
							cursor:
								handleImageActive(image.id) !== "active"
									? "pointer"
									: "not-allowed",
							background: handleColor(image.id),
						}}
						onClick={(e) =>
							handleImageSelect(
								image.id,
								index,
								e.shiftKey,
								lastSelectedImageIndex,
								setImageID,
								setLastSelectedImageIndex,
								sortedImages,
								handleImageActive,
							)
						}
					>
						<img
							src={
								image.src.length > 4
									? encodeURI(`${getCDNURL()}/image/${image.src}`)
									: "/sample.JPG"
							}
							alt={
								image.src.length > 4
									? encodeURI(`${getCDNURL()}/image/${image.src}`)
									: "/sample.JPG"
							}
							style={{ width: "100%" }}
						/>
					</Box>
				))}
			</Stack>
			<Button variant="contained" color="primary" onClick={handleSubmit}>
				Connect images
			</Button>
		</Stack>
	);
}
