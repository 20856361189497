import { Add, Clear, Delete } from "@mui/icons-material";
import {
	Box,
	Dialog,
	Divider,
	IconButton,
	Stack,
	Typography,
	Grid,
} from "@mui/material";
import { useContext } from "react";
import {
	Link,
	useLocation,
	useParams,
	useSearchParams,
} from "react-router-dom";
import AddPoleItem from "../AddPoleItem/AddPoleItem";
import { AnnotationContext } from "../provider";
import { getCDNURL } from "authentication/apiDetails";

function NotFound() {
	const [searchParams, setSearchParams] = useSearchParams();
	return (
		<Dialog
			open={true}
			sx={{ zIndex: 20000, p: 2 }}
			onClose={() => {
				searchParams.delete("box");
				setSearchParams(searchParams);
			}}
		>
			<Typography>Not found</Typography>
		</Dialog>
	);
}

export default function PoleBox() {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const poleID = Number.parseInt(searchParams.get("pole"));
	const boxID = Number.parseInt(searchParams.get("box"));
	const projectID = Number.parseInt(params.mission);

	const annotationState = useContext(AnnotationContext);

	const annotations = annotationState.data;
	const currentAnnotation = annotations.find(
		(annotation) => annotation.id === boxID,
	);

	if (!currentAnnotation) {
		return <NotFound />;
	}

	const boxItems = currentAnnotation.items;

	function deleteItem(itemID: number) {
		annotationState.deleteBoxItem(poleID, boxID, itemID);
	}

	return (
		<Dialog
			open={true}
			sx={{ zIndex: 20000 }}
			onClose={() => {
				if (searchParams.has("addPoleItem")) {
					searchParams.delete("addPoleItem");
				}
				if (searchParams.has("box")) {
					searchParams.delete("box");
				}
				setSearchParams(searchParams);
			}}
			maxWidth={location.pathname.endsWith("add") ? "xl" : "md"}
		>
			<Stack
				sx={{ p: 2 }}
				direction="row"
				spacing={1}
				divider={<Divider orientation="vertical" flexItem />}
			>
				<Stack spacing={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="h5" component="h2">
							Connected images
						</Typography>

						<IconButton
							onClick={() => {
								if (searchParams.has("addPoleItem")) {
									searchParams.delete("addPoleItem");
								}
								if (searchParams.has("box")) {
									searchParams.delete("box");
								}
								setSearchParams(searchParams);
							}}
						>
							{searchParams.has("addPoleItem") ? <Clear /> : <Add />}
						</IconButton>
					</Stack>
					<Divider sx={{ mb: 2, mt: 1 }} />
					<Grid container spacing={2}>
						{boxItems?.map((item) => {
							return (
								<Grid item xs={3}>
									<Box
										sx={{ width: "100%", maxWidth: 100, position: "relative" }}
									>
										<Link
											to={`/${projectID}/${item.image.id}${location.search}`}
										>
											<img
												alt="item"
												src={
													item.image.src.length < 4
														? "/sample.JPG"
														: encodeURI(
																`${getCDNURL()}/image/${item.image.src}`,
															)
												}
												style={{ width: "100%" }}
											/>
										</Link>
										<IconButton
											sx={{
												position: "absolute",
												top: 5,
												right: 5,
												width: 22,
												height: 22,
												background: "rgba(0,0,0,0.3)",
												"&:hover": {
													background: "rgba(0,0,0,0.7)",
												},
											}}
											onClick={() => {
												deleteItem(item.id);
											}}
										>
											<Delete htmlColor="white" sx={{ width: 18 }} />
										</IconButton>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Stack>
				{searchParams.has("addPoleItem") && <AddPoleItem boxItems={boxItems} />}
			</Stack>
		</Dialog>
	);
}
