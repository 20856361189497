import React from "react";
import { Collapse } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ImageList from "components/ImageLists";

export default function ListPopup() {
  const [searchParams] = useSearchParams();
  const showNavigation = !!parseInt(searchParams.get("lists") ?? "0");
  return (
    <Collapse in={showNavigation}>
      <ImageList compact />
    </Collapse>
  );
}
