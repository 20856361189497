import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { ObjectType, DpType } from "interfaces";

import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

type Props = {
  objectTypes: any;
  editCategory;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore */}
      <GridToolbarColumnsButton />
      {/* @ts-ignore */}
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function Categories({ objectTypes, editCategory }: Props) {
  let filteredObjects = objectTypes.filter((e) => e.id !== null);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              editCategory(
                filteredObjects.filter((obj) => obj.id === params.id)[0]
              );
            }}
          >
            <Edit />
          </IconButton>
          {params.value}
        </>
      ),
    },
    {
      field: "en_name",
      headerName: "English",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "se_name",
      headerName: "Swedish",
      minWidth: 200,
      flex: 1,
    },
  ];

  return (
    <Box sx={{ with: "100%", height: "100%" }}>
      <DataGrid
        rows={filteredObjects}
        columns={columns}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnMenu={true}
        sx={{ backgroundColor: "white", with: "100%", height: "100%" }}
      />
    </Box>
  );
}
