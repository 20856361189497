import { getAPIEndpoint } from "authentication/apiDetails";

export const rebuildPath = (
  pathname: string,
  query: { [key: string]: any },
  updatedQuery: { [key: string]: any } | null = null
) => {
  if (query == null) {
    query = {};
  }
  if (updatedQuery) {
    // Update query object
    Object.keys(updatedQuery).forEach((key) => {
      if (updatedQuery[key] === false) {
        delete query[key];
      } else {
        query[key] = updatedQuery[key];
      }
    });
  }

  // Transform query object to uri
  const newQueryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");
  return `${pathname}?${newQueryString}`;
};

export function setURIProperty(name, value) {
  // Get old URI
  const uriQuery = new URLSearchParams(window.location.search);

  // Delete property if it doesn't exist
  if (value.toString().length > 0) {
    uriQuery.set(name, value.toString());
  } else {
    uriQuery.delete(name);
  }

  let newPath = window.location.pathname + "?" + uriQuery.toString();
  newPath = decodeURIComponent(newPath);
  window.history.pushState({ urlPath: newPath }, "", newPath);
  window.location.replace(newPath);
}

export function getURIProperty(name) {
  // Get old URI
  const uriQuery = new URLSearchParams(window.location.search);
  return JSON.parse(uriQuery.get(name));
}

export function setMission(mission: number | string) {
  // Reroute old URI path to new URI path
  const uriQuery = new URLSearchParams(window.location.search);
  if (window.location.pathname) {
    let pathname: string = "";
    if (mission > 0) {
      pathname = "/" + mission.toString();
    }

    var queryObject = {};
    uriQuery.forEach((value, key) => {
      queryObject[key] = value;
    });
    const newPath = rebuildPath(pathname, queryObject);
    window.history.pushState({ urlPath: newPath }, "", newPath);
    window.location.replace(newPath);
  }
}

export function closeMenu(query: URLSearchParams | string) {
  let returnQuery;
  if (!!!query) {
    return new URLSearchParams();
  }
  if (!(query instanceof URLSearchParams)) {
    returnQuery = new URLSearchParams(query);
  } else {
    returnQuery = query;
  }
  if (returnQuery.has("menu")) {
    returnQuery.delete("menu");
  }
  return returnQuery;
}

export function getLambdaURL() {
  let url = "";
  if (window.location.origin === "https://dev.skyqraft.com") {
    url =
      "https://tp2mtvgknqhz2yu6mjcio7enbi0vsuyj.lambda-url.eu-north-1.on.aws";
  } else if (window.location.origin === "https://test.skyqraft.com") {
    url =
      "https://7oxfpwurs3s2gcsw7lztqx4l540xwagh.lambda-url.eu-north-1.on.aws";
  } else if (window.location.origin === "https://map.skyqraft.com") {
    url =
      "https://vagtwjhh5dulxr5r35raptpsq40jqsty.lambda-url.eu-north-1.on.aws";
  } else if (window.location.origin === "https://hawkeye.skyqraft.com") {
    url =
      "https://jwrhjhvmlpoqcdlfn53zv5ucce0dymgg.lambda-url.eu-north-1.on.aws";
  } else if (window.location.origin === "https://atom.skyqraft.com") {
    url =
      "https://gz3r3wgmr6tjrhrsvggdipp5iq0ebisj.lambda-url.eu-north-1.on.aws";
  } else if (window.location.origin === "https://us.skyqraft.com") {
    url =
      "https://j5kp6eauk7qrgfwnizpey6xhze0dzzzv.lambda-url.us-east-1.on.aws/";
  } else if (window.location.origin.endsWith("arkion.co")) {
    url = getAPIEndpoint();
  } else {
    url = "http://localhost:8080";
  }
  return url;
}
