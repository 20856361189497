import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { ImageSearch, Clear } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";

export default function SupervisorReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();
  const isSupervisorReview = location.pathname.includes("/supervisor-review");
  let newUrl = location.pathname;
  const regex = /\/supervisor-review/gi;
  newUrl = newUrl.replaceAll(regex, "");

  return (
    <RoleWrapper keyName="supervisorApprove">
      <MenuItem
        id="image-review-supervisor"
        tooltip={translations.ImageViewer.AnnotatorReview[language]}
        onClick={() => {
          if (isSupervisorReview) {
            navigate(`${newUrl}`);
          } else {
            navigate(`supervisor-review${location.search}`);
          }
        }}
      >
        {isSupervisorReview ? <Clear htmlColor="red" /> : <ImageSearch />}
      </MenuItem>
    </RoleWrapper>
  );
}
