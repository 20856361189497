import React from "react";
import { Collapse, Tooltip, Stack, Chip } from "@mui/material";
import translations from "translations";
import {
  Image,
  DateRangeOutlined,
  FlightTakeoff,
  AccountTree,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { ImageMeta } from "interfaces";
import TitleIcon from "@mui/icons-material/Title";
import { useLanguage } from "hooks";
type Props = {
  imageMeta: ImageMeta;
};

export default function InformatinPopup({ imageMeta }: Props) {
  const [searchParams] = useSearchParams();
  const { language } = useLanguage();
  const showNavigation = !!parseInt(searchParams.get("info") ?? "0");
	const hasFeedBay = !!imageMeta?.feed_bay
	const hasFeedStation = !!imageMeta?.feed_station
	let feedBayStationKey = null;
	if(hasFeedBay && hasFeedStation) {
		if(hasFeedBay && hasFeedStation) {
			feedBayStationKey = `${imageMeta.feed_station}:${imageMeta.feed_bay}`
		} else if (hasFeedBay) {
			feedBayStationKey = imageMeta.feed_bay
		} else if (hasFeedStation) {
			feedBayStationKey = imageMeta.feed_station
		} else {
			feedBayStationKey = null
		}
	}


  return !!imageMeta ? (
    <Collapse in={showNavigation}>
      <Stack direction="row" spacing={1} margin={1}>
        <Tooltip
          title={translations.ImageViewer.imageName[language]}
          placement="top"
          disableInteractive
          arrow
        >
          <Chip
            size={"small"}
            icon={<Image />}
            label={imageMeta?.name}
            style={{ margin: 5, backgroundColor: "#FFF" }}
          />
        </Tooltip>
        <Tooltip
          title={translations.ImageViewer.dateImageTaken[language]}
          placement="top"
          disableInteractive
          arrow
        >
          <Chip
            size={"small"}
            icon={<DateRangeOutlined />}
            label={imageMeta?.name?.split("_")[0] ?? "Unknown"}
            style={{ margin: 5, backgroundColor: "#FFF" }}
          />
        </Tooltip>
        <Tooltip
          title={translations.ImageViewer.flight[language]}
          placement="top"
          disableInteractive
          arrow
        >
          <Chip
            size={"small"}
            icon={<FlightTakeoff />}
            label={imageMeta?.flight_id}
            style={{ margin: 5, backgroundColor: "#FFF" }}
          />
        </Tooltip>
        {!!imageMeta?.pole_id && (
          <Tooltip
            title={translations.ImageViewer.pole[language]}
            placement="top"
            disableInteractive
            arrow
          >
            <Chip
              size="small"
              icon={<TitleIcon />}
              label={imageMeta?.pole_id}
              style={{ margin: 5, backgroundColor: "#FFF" }}
            />
          </Tooltip>
        )}
        {!!imageMeta?.pole_label && (
          <Tooltip
            title={translations.ImageViewer.Label[language]}
            placement="top"
            disableInteractive
            arrow
          >
            <Chip
              size="small"
              icon={<TitleIcon />}
              label={imageMeta?.pole_label}
              style={{ margin: 5, backgroundColor: "#FFF" }}
            />
          </Tooltip>
        )}
        {feedBayStationKey && (
          <Tooltip
            title={translations.ImageViewer.StationFeedbay[language]}
            placement="top"
            disableInteractive
            arrow
          >
            <Chip
              size="small"
              icon={<AccountTree />}
              label={feedBayStationKey}
              style={{ margin: 5, backgroundColor: "#FFF" }}
            />
          </Tooltip>
        )}
      </Stack>
    </Collapse>
  ) : null;
}
