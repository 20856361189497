import React, { ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { authorizedGet, getLambdaAxiosWithAuth } from "utils/request";
import PulseLoader from "react-spinners/PulseLoader";
import {
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Table,
  TableRow,
  Container,
  TableCell,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import NewReport from "views/Inbox/NewReport";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCurrentProject, useLanguage } from "hooks";
import { dateTimeToString } from "utils/utils";
import { FilterCountProvider } from "providers/FilterCountProvider";

interface Props {
  issueCategories;
  issueSeverities;
  customerId: number;
  demoMode: boolean;
  fixableDefects: boolean;
  skyqraftEmployee: boolean;
  projectName: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DefectReport({ demoMode }: Props): ReactElement {
  const { language } = useLanguage();
  const [value, setValue] = React.useState(0);
  const project = useCurrentProject();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);
  const [reportCount, setReportCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [poleCount, setPoleCount] = useState(0);
  const [OIDCount, setOIDCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    updateNumbers();
  }, []);

  async function getDataFromDb(endpoint: string) {
    const axiosInstance = await getLambdaAxiosWithAuth();
    const response = await axiosInstance.get(endpoint, {
      responseType: "blob",
      headers: {
        MissionID: project.id,
      },
    });
    return response.data;
  }

  function downloadAsFile(payload, namePrefix: string, fileExtension: string) {
    const url = window.URL.createObjectURL(
      new Blob([payload], { type: "application/octet-stream" })
    );
    const link = document.createElement("a");
    link.href = url;
    const d = new Date();
    const timeName = dateTimeToString(d);
    link.setAttribute(
      "download",
      `${namePrefix}_project_${project.name}_${project.year}_${timeName}${fileExtension}`
    );
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async function getReport({
    startMessage,
    endpoint,
    successMessage,
    failMessage,
    filenamePrefix,
    reportType,
  }: {
    startMessage: string;
    endpoint: string;
    successMessage: string;
    failMessage: string;
    filenamePrefix: string;
    reportType: "csv" | "excel" | "binary";
  }) {
    const toastPointer = toast.info(startMessage, {
      autoClose: false,
    });
    try {
      const response = await getDataFromDb(endpoint);

      toast.update(toastPointer, {
        type: toast.TYPE.SUCCESS,
        render: successMessage,
        autoClose: 5000,
      });

      // Create the extension for the file
      let extension = "";
      if (reportType === "csv") {
        extension = ".csv";
      } else if (reportType === "excel") {
        extension = ".xlsx";
      } else if (reportType === "binary") {
        extension = "";
      }
      downloadAsFile(response, filenamePrefix, extension);
    } catch (err) {
      console.error(err);
      toast.update(toastPointer, {
        type: toast.TYPE.ERROR,
        render: failMessage,
        autoClose: 5000,
      });
    }
  }

  async function downloadCSV() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append("language", language);
    searchParams.append("demo", demoMode ? "true" : "false");
    searchParams.append("processDefects", "false");
    const searchString = searchParams.toString();

    let endpoint = "/object/report/csv";
    if (searchString) {
      endpoint += `?${searchString}`;
    }

    await getReport({
      endpoint,
      startMessage: "Generating CSV file...",
      successMessage: "CSV generated",
      failMessage: "CSV generation failed.",
      filenamePrefix: "defect_report",
      reportType: "csv",
    });
  }

  async function downloadExcel() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append("language", language);
    searchParams.append("demo", demoMode ? "true" : "false");
    searchParams.append("processDefects", "false");
    const searchString = searchParams.toString();

    let endpoint = "/object/report/excel";
    if (searchString) {
      endpoint += `?${searchString}`;
    }
    await getReport({
      endpoint,
      startMessage: "Generating Excel file...",
      successMessage: "Excel generated",
      failMessage: "Excel generation failed.",
      filenamePrefix: "defect_report",
      reportType: "excel",
    });
  }

  const downloadDigPro = (index) => {
    switch (index) {
      case 0:
        if (OIDCount < reportCount) {
          setOpenDialog(true);
        } else {
          downloadDigproFile("csv", true);
        }
        break;
      case 1:
        downloadDigproFile("binary", true);
        break;
      case 2:
        toast.warning("This feature is not enabled yet");
        break;
      default:
        toast.error("Invalid index provided");
        break;
    }
  };

  async function downloadDigproFile(
    reportType: "csv" | "excel" | "binary",
    poles: boolean
  ) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append("language", language);
    searchParams.append("poles", poles ? "true" : "false");
    searchParams.append("processDefects", "false");

    let endpoint = `/report/defect/dppower/${reportType}`;
    if (searchParams.toString()) {
      endpoint += `?${searchParams.toString()}`;
    }

    await getReport({
      endpoint,
      startMessage: "Generating DigPro file...",
      successMessage: "DigPro file generated",
      failMessage: "DigPro file generation failed.",
      filenamePrefix: "defect_report_digpro",
      reportType,
    });
  }

  const updateNumbers = () => {
    setLoading(true);
    authorizedGet<{ length; images; poles; oids }>(
      `/object/report/count${window.location.search}`
    ).then((response) => {
      if (response) {
        setReportCount(response.length);
        setImageCount(response.images);
        setPoleCount(response.poles);
        setOIDCount(response.oids);
        setLoading(false);
      } else {
        setReportCount(0);
        setImageCount(0);
        setPoleCount(0);
        setOIDCount(0);
        setLoading(false);
      }
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Retrigger when filters change
  useEffect(() => {
    updateNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  return (
    <RoleWrapper keyName="defectReport">
      <div style={{ margin: 10 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="PDF" {...a11yProps(0)} />
            <Tab label="Excel" {...a11yProps(1)} />
            <Tab label="CSV" {...a11yProps(2)} />
            <Tab label="KML" {...a11yProps(3)} disabled />
            <Tab label="DPPower" {...a11yProps(4)} />
            <Tab label="Netbas" {...a11yProps(5)} disabled />
            <Tab label="Trimble" {...a11yProps(6)} disabled />
            <Tab label="SAP" {...a11yProps(7)} disabled />
            <Tab label="Maximo" {...a11yProps(8)} disabled />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FilterCountProvider>
            <NewReport />
          </FilterCountProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Container>
            <Typography sx={{ mb: 1 }}>
              {translations.DefectReport.Excel.Description[language]}
            </Typography>
            <Typography>
              {translations.DefectReport.Numbers[language]}
            </Typography>
            <Table sx={{ maxWidth: 300, my: 2 }}>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Defect[language]}
                </TableCell>
                <TableCell>{reportCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Images[language]}
                </TableCell>
                <TableCell>{imageCount}</TableCell>
              </TableRow>
            </Table>
            <br />
            <Typography fontStyle="italic" sx={{ mb: 1 }}>
              {translations.DefectReport.FilterExplanation[language]}
            </Typography>
            <Button
              variant="contained"
              style={{
                color: "white",
                marginLeft: 5,
                textTransform: "none",
              }}
              onClick={downloadExcel}
            >
              {translations.Menu.Tools.DefectReport.DownloadExcel[language]}
            </Button>
            {loading && <PulseLoader size={5} />}
          </Container>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Container>
            <Typography sx={{ mb: 1 }}>
              {translations.DefectReport.CSV.Description[language]}
            </Typography>
            <Typography>
              {translations.DefectReport.Numbers[language]}
            </Typography>
            <Table sx={{ maxWidth: 300, my: 2 }}>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Defect[language]}
                </TableCell>
                <TableCell>{reportCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Images[language]}
                </TableCell>
                <TableCell>{imageCount}</TableCell>
              </TableRow>
            </Table>
            <br />
            <Typography fontStyle="italic" sx={{ mb: 1 }}>
              {translations.DefectReport.FilterExplanation[language]}
            </Typography>
            <Button
              variant="contained"
              style={{
                color: "white",
                marginLeft: 5,
                textTransform: "none",
              }}
              onClick={downloadCSV}
            >
              {translations.Menu.Tools.DefectReport.DownloadCSV[language]}
            </Button>
            {loading && <PulseLoader size={5} />}
          </Container>
        </TabPanel>
        <TabPanel value={value} index={3}>
          Coming soon...
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Container>
            <Typography sx={{ mb: 1 }}>
              {translations.DefectReport.DPPower.Description[language]}
            </Typography>
            <Typography>
              {translations.DefectReport.Numbers[language]}
            </Typography>
            <Table sx={{ maxWidth: 300, my: 2 }}>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Defect[language]}
                </TableCell>
                <TableCell>{reportCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Images[language]}
                </TableCell>
                <TableCell>{imageCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.Menu.Tools.DefectReport.Poles[language]}
                </TableCell>
                <TableCell>{poleCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.DefectReport.DefectsWithoutOID[language]}
                </TableCell>
                <TableCell>{reportCount - OIDCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {translations.DefectReport.DefectsWithoutPoles[language]}
                </TableCell>
                <TableCell>{reportCount - poleCount}</TableCell>
              </TableRow>
            </Table>

            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {translations.DefectReport.MissingOID[language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {translations.DefectReport.SomePolesAreMission[language]}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                    downloadDigproFile("csv", true);
                  }}
                >
                  {translations.DefectReport.AllDefects[language]} (
                  {reportCount})
                </Button>
                <Button
                  autoFocus
                  onClick={() => {
                    setOpenDialog(false);
                    downloadDigproFile("csv", false);
                  }}
                >
                  {translations.DefectReport.AllDefectsWithoutOID[language]} (
                  {reportCount - OIDCount})
                </Button>
              </DialogActions>
            </Dialog>
            <br />
            <Typography fontStyle="italic" sx={{ mb: 1 }}>
              {translations.DefectReport.FilterExplanation[language]}
            </Typography>

            <Button
              variant="contained"
              style={{
                color: "white",
                marginLeft: 5,
                textTransform: "none",
              }}
              onClick={() => downloadDigPro(0)}
            >
              {translations.Menu.Tools.DefectReport.ExportDPCSV[language]}
            </Button>
            {loading && <PulseLoader size={5} />}
          </Container>
        </TabPanel>
      </div>
    </RoleWrapper>
  );
}
