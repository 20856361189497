import { axiosInstance } from "utils/request";

export interface IStateMachine {
  id: number;
  name: string;
  description: string;
  inputSchema: Record<string, unknown>;
  outputSchema: Record<string, unknown>;
  arn: string;
  created: string;
  creator: {
    id: number;
    name: string;
    email: string;
  };
}

export interface IStateMachineExecution {
  id: number;
  name: string;
  input: Record<string, unknown>;
  output: Record<string, unknown> | null;
  status: string;
  state_machine_id: number;
  created: string;
  creator: {
    id: number;
    name: string;
    email: string;
  };
}

export async function getStateMachines() {
  const response = await axiosInstance.get<{
    state_machines: IStateMachine[];
  }>("/batch/state_machine");
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data.state_machines;
}

export async function getStateMachine(stateMachineId: number) {
  // A list of IStateMachineExecution exists in IStateMachine under the key "executions"

  type StateMachineResponse = IStateMachine & {
    executions: IStateMachineExecution[];
  };
  const response = await axiosInstance.get<StateMachineResponse>(
    `/batch/state_machine/${stateMachineId}`
  );
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
}

export async function getStateMachineExecutions(stateMachineId: number) {
  const response = await axiosInstance.get<{
    executions: IStateMachineExecution[];
  }>(`/batch/state_machine/${stateMachineId}/execution`);
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data.executions;
}

export async function startStateMachineExecution(
  stateMachineId: number,
  name: string,
  input: Record<string, unknown>
) {
  const response = await axiosInstance.post(
    `/batch/state_machine/${stateMachineId}/execution`,
    {
      name,
      input,
    }
  );
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
}

export async function createStateMachine(
  stateMachine: Omit<IStateMachine, "id" | "creator" | "created">
) {
  const response = await axiosInstance.post(
    "/batch/state_machine",
    stateMachine
  );
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
}

export async function deleteStateMachine(stateMachineId: number) {
  const response = await axiosInstance.delete(
    `/batch/state_machine/${stateMachineId}`
  );
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
}

export async function updateStateMachine(stateMachine: IStateMachine) {
  const response = await axiosInstance.put(
    `/batch/state_machine/${stateMachine.id}`,
    stateMachine
  );
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
}
