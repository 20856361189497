import { ReactElement, useState } from "react";
import { Box, Collapse, Stack, IconButton } from "@mui/material";
import MapToggle from "components/MapToggle";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Home,
  LocationOn,
} from "@mui/icons-material";
import { ImageMeta } from "interfaces";
import { useLocation } from "react-router-dom";

import "./style.scss";
import { zoomToImage } from "utils/map";

interface IProps {
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
}

export default function InfoWindow({ gmap, imageMeta }: IProps): ReactElement {
  const location = useLocation();

  const annotationToolOpen = location.pathname.includes("/annotate");
  const supervisorReviewOpen = location.pathname.includes("/supervisor-review");
  const machineReviewOpen = location.pathname.includes("/machine-review");
  const falsepositiveReviewOpen = location.pathname.includes(
    "/falsepositive-review"
  );
  const falseReviewOpen = location.pathname.includes(
    "/superfalsepositive-review"
  );

  const modeOpen =
    annotationToolOpen ||
    supervisorReviewOpen ||
    machineReviewOpen ||
    falsepositiveReviewOpen ||
    falseReviewOpen;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div id="infoWindow">
      <Stack alignItems="flex-end" spacing={1}>
        <Collapse in={collapsed} orientation="horizontal" collapsedSize={40}>
          <Stack direction="row" alignItems="flex-start">
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() => setCollapsed(!collapsed)}
              sx={{
                bgcolor: "rgba(255,255,255,0.75)",
                "&:hover": {
                  bgcolor: "#FFF",
                },
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <Collapse in={collapsed} orientation="vertical" collapsedSize={40}>
              <Box
                sx={{
                  bgcolor: "rgba(255,255,255,0.75)",
                  borderRadius: 2,
                  borderTopLeftRadius: 0,
                }}
              >
                <MapToggle />
              </Box>
            </Collapse>
          </Stack>
        </Collapse>
        <IconButton
          sx={{
            bgcolor: "rgba(255,255,255,0.75)",
            "&:hover": {
              bgcolor: "#FFF",
            },
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => {
            if (gmap) {
              const bounds = new google.maps.LatLngBounds();
              // biome-ignore lint/complexity/noForEach: Google maps is not enumerable
              gmap.data.forEach((feature) => {
                feature.getGeometry().forEachLatLng((latlng) => {
                  bounds.extend(latlng);
                });
              });
              gmap.fitBounds(bounds);
            }
          }}
        >
          <Home />
        </IconButton>
        {!!imageMeta?.lat && (
          <IconButton
            sx={{
              bgcolor: "rgba(255,255,255,0.75)",
              "&:hover": {
                bgcolor: "#FFF",
              },
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onClick={() => {
              zoomToImage(gmap, imageMeta.lat, imageMeta.lng);
              const referenceWidth = modeOpen ? 0.8 : 0.4;
              const slit = 1 - referenceWidth;
              const offset = referenceWidth - 0.5;
              const x = offset + slit / 2;
              gmap.panBy(-x * window.innerWidth, 0);
            }}
          >
            <LocationOn />
          </IconButton>
        )}
      </Stack>
    </div>
  );
}
