import React from "react";
import MenuItem from "components/MenuItem";
import translations from "translations";
import Select from "react-select";
import { useLanguage } from "hooks";

interface IProps {
  clusterMethod: string;
  setClusterMethod;
}

function ClusterSwitch({ clusterMethod, setClusterMethod }: IProps) {
  const { language } = useLanguage();
  const options = [
    { value: "pole", label: "Pole" },
    { value: "cluster", label: "Cluster (Beta)" },
    { value: "proximity", label: "Proximity" },
  ];

  return (
    <MenuItem
      id="proximitySwitch"
      passive
      tooltip={translations.Menu.Settings.ClusterMethod[language]}
    >
      <p>{translations.Menu.Settings.ClusterMethod[language]}</p>
      <Select
        className="pr-10 pl-10 grow"
        name={"Cluster Method"}
        options={options}
        value={options.find((o) => o.value === clusterMethod)}
        onChange={(e) => {
          setClusterMethod(e.value);
        }}
      />
    </MenuItem>
  );
}
export default ClusterSwitch;
