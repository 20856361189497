import FullPage from "components/FullPage";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "hooks";
import { Divider, Typography, Box } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

export default function PoleStatusOptions() {
  const TowerTemplateEditor = useTranslation("TowerTemplateEditor");
  const location = useLocation();
  const tabs = [
    {
      label: "Templates",
      link: "/options/pole/status/template",
    },
    {
      label: "Components",
      link: "/options/pole/status/component",
    },
  ];

  let currentTab = 0;

  for (let i = 0; i < tabs.length; i++) {
    if (location.pathname.includes(tabs[i].link)) {
      currentTab = i;
      break;
    }
  }

  return (
    <FullPage title={TowerTemplateEditor} tabs={tabs} tab={currentTab}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InfoOutlined fontSize="small" sx={{ mr: 2 }} />
        <Typography variant="body2">
          You can only remove things from old templates here. Creating new
          templates and adding components to the new template is done via the
          pole editor on one of the poles in a project.
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Outlet />
    </FullPage>
  );
}
