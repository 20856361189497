import { toast } from "react-toastify";
import { RootState } from "state/reducers";
import { IReviewObjectList } from "state/reducers/objectreducer";
import { authorizedPost, axiosInstance } from "utils/request";

export function approveFalseReviewObjects(
  image: number,
  imageObjectIDs: number[] = null,
  callback: Function = null
) {
  return async () => {
    const response = await authorizedPost("/review/false", {
      image: image,
      imageObjectIDs: imageObjectIDs,
    });

    if (response) {
      callback?.();
    }
  };
}


export function resetFalseReviewObjects() {
  return {
    type: "SET_FALSE_REVIEW_OBJECTS",
    payload: {
      image: null,
      objects: [],
      current_image_position: null,
      total_images_to_review: null,
    },
  };
}

export function getFalseReviewObjects() {

  return async (dispatch, getState) => {
    const baseQuery = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};
    const state: RootState = getState();
    if (baseQuery.has("detection")) {
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("detection")) {
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("flagged")) {
      params.detection = baseQuery.get("flagged");
    }
    if (baseQuery.has("list")) {
      params.detection = baseQuery.get("list");
    }
    if (baseQuery.has("actors")) {
      params.detection = baseQuery.get("actors");
    }

    const projectID = state.mission?.id ?? -1;

    const review_objects = await axiosInstance.get<IReviewObjectList>(
      "/review/false",
      {
        headers: { MissionID: projectID.toString() },
        params,
      }
    );

    if (review_objects.data) {
      dispatch({
        type: "SET_FALSE_REVIEW_OBJECTS",
        payload: review_objects.data,
      });
    } else {
      toast.error("No supervisor review objects found");
    }
  };
}
