import { useContext, useRef } from "react";
import { AnnotationContext } from "../../../provider";
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  AvatarGroup,
  CardActionArea,
  Box,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import EditObjectType from "./EditObjectType";
import EditDefectType from "./EditDefectType";
import EditSeverity from "./EditSeverity";
import { getClientObjectTypeName } from "utils/utils";
import AddType from "./AddType";
import TypeSelector from "./TypeSelector";
import TypeSearch from "./TypeSearch";
import DeleteTypeButton from "./DeleteTypeButton";
import InfoCommentBox from "./InfoCommentBox";
import WorkflowMenu from "./WorkflowMenu";
import { checkRegionUpdate } from "../Utils/index";
import { ISpecialAnnotation } from "views/AnnotationTool/provider/AnnotationProvider/context";
import { useSelector } from "hooks";
import "../style.scss";

interface IProps {
  annotation: ISpecialAnnotation;
  expandLength?: number;
}

export default function ListItem({ annotation, expandLength = 0 }: IProps) {
  const user = useSelector((state) => state.user);
  const issueSeverities = useSelector((state) => state.objects.issueSeverities);
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const {
    setSelectedAnnotation,
    updateAnnotation,
    setSavedTypes,
    originalAnnotations,
    steelworkObjectTypeId,
  } = useContext(AnnotationContext);

  const listItemRef = useRef(null);

  const objectTypesNew = objectTypes.map((e) => getClientObjectTypeName(e.id));

  //This is for setting the color of the list item
  let color = "#FFFFFF";
  const checkRegion = checkRegionUpdate(
    annotation,
    originalAnnotations,
    objectTypesNew
  );

  //Check if item is new
  if (annotation.id.includes("n")) {
    color = "#DFD";
  }
  //check if item has been altered
  if (!checkRegion.new && !!checkRegion.canSave && !checkRegion.saved) {
    color = "#FFD";
  }
  //Check if item is missing something
  if (!checkRegion.canSave) {
    color = "#FDD";
  }

  return (
    <Card
      ref={listItemRef}
      className={`list-item ${annotation.highlighted ? "active" : ""}`}
      sx={{ maxWidth: 358, margin: "5px" }}
    >
      <CardActionArea
        onClick={() =>
          annotation.highlighted
            ? setSelectedAnnotation(null)
            : setSelectedAnnotation(annotation.id)
        }
      >
        <CardHeader
          sx={{
            padding: "0px 7px;",
            height: "40px",
            ".MuiCardHeader-avatar": {
              marginRight: "3px",
            },
            backgroundColor: color,
          }}
          avatar={
            <AvatarGroup spacing={"small"} sx={{ marginRight: "4px" }}>
              {annotation.types.map((type) => (
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    bgcolor: getClientObjectTypeName(type)?.color,
                  }}
                >
                  {/* This frament is to not render the profile avatar icon */}
                  <></>
                </Avatar>
              ))}
            </AvatarGroup>
          }
          action={
            expandLength > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "6px 9px 0px 0px",
                  textAlign: "center",
                }}
              >
                {expandLength}
                <ExpandMore />
              </div>
            ) : (
              annotation.types.length === 1 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <WorkflowMenu
                    annotation={annotation}
                    index={0}
                    user={user}
                    small={false}
                  />
                </div>
              )
            )
          }
          title={
            <div className="titleText">
              <h3 id="clickExpand">
                {getClientObjectTypeName(annotation.types[0])?.name}
              </h3>
            </div>
          }
        />
      </CardActionArea>
      <Collapse in={annotation.highlighted} timeout="auto" unmountOnExit>
        {annotation.types.map((type, index) => (
          <CardContent sx={{ padding: 0 }}>
            <TypeSearch
              objectTypes={objectTypesNew}
              issueSeverities={issueSeverities}
              region={annotation}
              onChangeRegion={(e) => {
                updateAnnotation(e.id, e);
              }}
              setSelectedType={(e) => {
                setSavedTypes(e[0]);
              }}
              index={index}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {annotation.types.includes(steelworkObjectTypeId) ? (
                  <TextField
                    id="itemId"
                    label="Item id"
                    size="small"
                    sx={{ width: "300px", margin: "3px 9px 8px 20px" }}
                    fullWidth
                    value={annotation.item_id}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      updateAnnotation(annotation.id, {
                        ...annotation,
                        item_id: event.target.value,
                      });
                    }}
                  />
                ) : (
                  <TypeSelector
                    region={annotation}
                    objectTypes={objectTypesNew}
                    issueSeverities={issueSeverities}
                    onChangeRegion={(e) => {
                      updateAnnotation(e.id, e);
                    }}
                    saveObject={(e) => updateAnnotation(e.id, e)}
                    index={index}
                    annotatorObjectColor={user.annotator_color}
                    horizontal={annotation.types.length === 1}
                  />
                )}
                <DeleteTypeButton
                  annotation={annotation}
                  user={user}
                  index={index}
                />
              </Box>

              {annotation.types.length > 1 && (
                <WorkflowMenu
                  annotation={annotation}
                  index={index}
                  user={user}
                  small={true}
                />
              )}
            </Box>
            <EditObjectType
              region={annotation}
              objectTypes={objectTypesNew}
              saveObject={(e) => updateAnnotation(e.id, e)}
              index={index}
            />
            <EditDefectType
              region={annotation}
              objectTypes={objectTypesNew}
              saveObject={(e) => updateAnnotation(e.id, e)}
              index={index}
            />
            <EditSeverity
              region={annotation}
              issueSeverities={issueSeverities}
              saveObject={(e) => updateAnnotation(e.id, e)}
              index={index}
            />
            <InfoCommentBox annotation={annotation} index={index} />
          </CardContent>
        ))}
        {!annotation.types.includes(steelworkObjectTypeId) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "2px 14px 12px 13px",
            }}
          >
            <AddType annotation={annotation} isDefect={false} />
            <AddType annotation={annotation} isDefect={true} />
          </Box>
        )}
      </Collapse>
    </Card>
  );
}
