import { Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import CategoryItem from "./CategoryItem";
import OptionItem, { IOption } from "./OptionItem";
import { IOptionCategory } from "./CategoryItem";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  categories: IOptionCategory[];
  onChange: (optionIDs: number[]) => void;
  value: number[];
  loading?: boolean;
}

function isCategoryCompletelySelected(
  category: IOptionCategory,
  selectedOptions: number[]
) {
  return category.options.every((o) => selectedOptions.includes(o.id));
}

function filterMap(category: IOptionCategory, searchString: string) {
  let shouldBeVisible = false;
  // Check if category name matches the searchstring
  if (category.name.toLowerCase().includes(searchString.toLowerCase())) {
    shouldBeVisible = true;
  }
  // Check if any option name matches the searchstring
  const options = category.options.filter((option) =>
    option.name.toLowerCase().includes(searchString.toLowerCase())
  );
  if (options.length > 0) {
    shouldBeVisible = true;
  }
  if (!shouldBeVisible) {
    return null;
  }
  return {
    ...category,
    options: options,
  };
}

function isCategoryPartiallySelected(
  category: IOptionCategory,
  selectedOptions: number[]
) {
  return (
    category.options.some((o) => selectedOptions.includes(o.id)) &&
    !isCategoryCompletelySelected(category, selectedOptions)
  );
}

function ExpandedSelect({ categories, onChange, value, loading }: IProps) {
  const [searchString, setSearchString] = useState("");
  const { language } = useLanguage();
  const selectedOptions = value;
  const setSelectedOptions = onChange;

  const filteredCategories = categories
    .map((c) => filterMap(c, searchString))
    .filter((a) => a !== null);

  function onCategorySelect(category: IOptionCategory) {
    if (isCategoryCompletelySelected(category, selectedOptions)) {
      const newOptions = selectedOptions.filter(
        (o) => !category.options.map((o) => o.id).includes(o)
      );
      setSelectedOptions(newOptions);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        ...category.options.map((o) => o.id),
      ]);
    }
  }

  function onOptionSelect(option: IOption) {
    if (selectedOptions.includes(option.id)) {
      const newOptions = selectedOptions.filter((o) => o !== option.id);
      setSelectedOptions(newOptions);
    } else {
      setSelectedOptions([...selectedOptions, option.id]);
    }
  }

  if (filteredCategories.length === 1) {
    const cat = filteredCategories[0];
    return (
      <Stack>
        <TextField
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={translations.Filter.EnterNameOfType[language]}
          variant="standard"
          label={translations.Filter.SearchForObjectType[language]}
          sx={{ mb: 2 }}
        />
        <OptionItem
          option={{
            id: cat.id,
            name: cat.name,
            count: cat.options.reduce((acc, o) => acc + o.count, 0),
          }}
          onSelect={() => onCategorySelect(cat)}
          selected={isCategoryCompletelySelected(cat, selectedOptions)}
          loading={loading}
        />

        {cat.options.length === 0 && (
          <Stack
            alignItems="center"
            justifyContent="center"
            border="1px solid #DADADA"
            borderRadius={4}
            py={2}
            width="wrap-content"
          >
            <Typography variant="h2">🕵️</Typography>
            <Typography
              sx={{
                textWrap: "pretty",
                overflow: "hidden",
                px: 2,
              }}
            >
              {translations.Filter.NoTypeMatches[language]} "{searchString}"
            </Typography>
          </Stack>
        )}

        <Stack sx={{ ml: 2 }}>
          {cat.options.map((option) => (
            <OptionItem
              option={option}
              onSelect={onOptionSelect}
              selected={selectedOptions.includes(option.id)}
              loading={loading}
            />
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack>
      <TextField
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder={translations.Filter.EnterNameOfType[language]}
        variant="standard"
        label={translations.Filter.SearchForObjectType[language]}
        sx={{ mb: 2 }}
      />
      {filteredCategories.length === 0 && (
        <Stack
          alignItems="center"
          justifyContent="center"
          border="1px solid #DADADA"
          borderRadius={4}
          py={2}
          width="wrap-content"
        >
          <Typography variant="h2">🕵️</Typography>
          <Typography
            sx={{
              textWrap: "pretty",
              overflow: "hidden",
              px: 2,
            }}
          >
            {translations.Filter.NoTypeMatches[language]} "{searchString}"
          </Typography>
        </Stack>
      )}
      {filteredCategories.map((c) =>
        c.options.length === 0 ? null : (
          <CategoryItem
            category={c}
            onSelect={onCategorySelect}
            selected={isCategoryCompletelySelected(c, selectedOptions)}
            partiallySelected={isCategoryPartiallySelected(c, selectedOptions)}
            loading={loading}
          >
            <Stack sx={{ pl: 4 }}>
              {c.options.map((option) => (
                <OptionItem
                  option={option}
                  onSelect={onOptionSelect}
                  selected={selectedOptions.includes(option.id)}
                />
              ))}
            </Stack>
          </CategoryItem>
        )
      )}
    </Stack>
  );
}

export default ExpandedSelect;
export { ExpandedSelect };
export type { IOptionCategory, IOption };
