import { MutableRefObject } from "react";

// Define a utility function
export function getCanvasBox(
  canvasReference: MutableRefObject<HTMLCanvasElement | null>
) {
  if (!canvasReference.current)
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  const { width, height, top, left } =
    canvasReference.current.getBoundingClientRect();
  return { width, height, top, left };
}

export function isPointInBox(
  boxX: number,
  boxY: number,
  boxW: number,
  boxH: number,
  boxRotation: number,
  mouseX: number,
  mouseY: number
) {
  if (boxRotation === 0) {
    return mouseX > boxX &&
            mouseX < boxX + boxW &&
            mouseY > boxY &&
            mouseY < boxY + boxH;
  }
  const boxRotationInRadians = (boxRotation * Math.PI) / 180;
  const boxCenter = {
    x: boxX + boxW / 2,
    y: boxY + boxH / 2,
  };

  const topLeftPoint = {
    x: boxX,
    y: boxY,
  };
  const topRightPoint = {
    x: boxX + boxW,
    y: boxY,
  };
  const bottomLeftPoint = {
    x: boxX,
    y: boxY + boxH,
  };
  const bottomRightPoint = {
    x: boxX + boxW,
    y: boxY + boxH,
  };

  const rotatePoint = (point: { x: number; y: number }) => {
    const x = point.x - boxCenter.x;
    const y = point.y - boxCenter.y;
    const rotatedX =
      x * Math.cos(boxRotationInRadians) - y * Math.sin(boxRotationInRadians);
    const rotatedY =
      x * Math.sin(boxRotationInRadians) + y * Math.cos(boxRotationInRadians);
    return {
      x: rotatedX + boxCenter.x,
      y: rotatedY + boxCenter.y,
    };
  };

  const A = rotatePoint(topLeftPoint);
  const B = rotatePoint(topRightPoint);
  const C = rotatePoint(bottomLeftPoint);
  const D = rotatePoint(bottomRightPoint);

  const clickedPoint = {
    x: mouseX,
    y: mouseY,
  };
  function triangleArea(
    A: { x: number; y: number },
    B: { x: number; y: number },
    C: { x: number; y: number }
  ) {
    return (
      Math.abs(
        B.x * A.y -
          A.x * B.y +
          (C.x * B.y - B.x * C.y) +
          (A.x * C.y - C.x * A.y)
      ) / 2
    );
  }
  const APD = triangleArea(A, D, clickedPoint);
  const DPC = triangleArea(D, clickedPoint, C);
  const CPB = triangleArea(C, clickedPoint, B);
  const PBA = triangleArea(clickedPoint, B, A);
  const totalArea = APD + DPC + CPB + PBA;
  const boxArea = boxW * boxH;
  return totalArea <= boxArea;
}
